import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {apiPost} from '../api';
import {addRequestCases, requestDefaultState} from './reducerUtils';


export const foldersSortOptions = {
  creationDateAsc: 'creationDateAsc',
  creationDateDesc: 'creationDateDesc',
  labelAsc: 'labelAsc',
  labelDesc: 'labelDesc',
  recommended: 'recommended',
};

export const pinsSortOptions = {
  creationDateAsc: 'creationDateAsc',
  creationDateDesc: 'creationDateDesc',
  labelAsc: 'labelAsc',
  labelDesc: 'labelDesc',
  likes: 'likes',
};

export const pinsFilterOptions = {
  all: null,
  createdByUser: 'createdByUser',
  likedByUser: 'likedByUser',
};

const initialSearchState = {
  ...requestDefaultState,
  filter: null,
  filterOptions: [
    {emoji: '♾️', label: '♾️ All', value: pinsFilterOptions.all},
    {emoji: '😎', label: '😎 My Places', value: pinsFilterOptions.createdByUser},
    {emoji: '💖', label: '💖 My Favorites', value: pinsFilterOptions.likedByUser},
  ],
  sort: pinsSortOptions.likes,
  sortOptions: [
    // {label: 'Recommended', value: sortOptions.recommended},
    {label: '💞 Most Liked', value: pinsSortOptions.likes},
    {label: '🐣 Newest', value: pinsSortOptions.creationDateDesc},
    {label: '🐓 Oldest', value: pinsSortOptions.creationDateAsc},
    {label: '🔼 A-Z', value: pinsSortOptions.labelAsc},
    {label: '🔽 Z-A', value: pinsSortOptions.labelDesc},
  ],
};


const initialState = {
  folderDelete: requestDefaultState,
  folderEditForm: {},
  folderEditFormSave: requestDefaultState,
  folderOpened: undefined, // folder document
  foldersListOthers: requestDefaultState,
  foldersListSearch: {
    ...initialSearchState,
    filterOptions: [
      {emoji: '♾️', label: '♾️ All', value: pinsFilterOptions.all},
      {emoji: '😎', label: '😎 My Lists', value: pinsFilterOptions.createdByUser},
    ],
    sort: foldersSortOptions.recommended,
    sortOptions: [
      {label: '🦉 Recommended', value: foldersSortOptions.recommended},
      {label: '🐣 Newest', value: foldersSortOptions.creationDateDesc},
      {label: '🐓 Oldest', value: foldersSortOptions.creationDateAsc},
      {label: '🔼 A-Z', value: foldersSortOptions.labelAsc},
      {label: '🔽 Z-A', value: foldersSortOptions.labelDesc},
    ],
  },
  foldersListSharedInWorld: requestDefaultState,
  foldersListUser: requestDefaultState,
  isNewDataAvailable: false,
  pinsBalanceRequest: requestDefaultState,
  pinDelete: requestDefaultState,
  pinEditForm: {},
  pinEditFormSave: requestDefaultState,
  pinRating: requestDefaultState,
  pinsListFolder: initialSearchState,
  pinsListSearch: initialSearchState,
};

export const folderEditFormSaveRequest = createAsyncThunk(
  'pins/folderEditFormSaveRequest',
  async (payload, {getState}) => {
    const {pins: {folderEditForm}} = getState();
    const {data} = await apiPost(`/folders/createOrUpdate`, folderEditForm);
    return data;
  },
);

export const pinsBalanceRequest = createAsyncThunk(
  'pins/pinsBalanceRequest',
  async ({}, thunkAPI) => {
    const {data} = await apiPost(`/pins/balance`, {});
    return data;
  },
);

export const pinEditFormSaveRequest = createAsyncThunk(
  'pins/pinEditFormSaveRequest',
  async (payload, {dispatch, getState}) => {
    const {inApp: {world: {_id: worldId}}, pins: {folderEditForm}} = getState();

    if (!_.isEmpty(folderEditForm)) {
      const {error, payload} = await dispatch(folderEditFormSaveRequest());
      if (error) throw error;
      dispatch(pinEditFormUpdate({folderId: payload._id}));
    }

    // for browser-only testing
    // dispatch(pinEditFormUpdate({
    //   location: {
    //     Latitude: 0, Longitude: 0
    //   }
    // }));

    const {pins: {pinEditForm}} = getState();
    const {data} = await apiPost(`/pins/createOrUpdate`, {
      ...pinEditForm,
      worldId,
    });
    return data;
  },
);

export const pinsSearchRequest = createAsyncThunk(
  'pins/pinsSearchRequest',
  async ({isNew, searchString = ''}, {getState}) => {
    const {inApp: {world: {_id: worldId}}, pins: {pinsListSearch}} = getState();
    const {filter, folderOpened, sort} = pinsListSearch;
    const {data} = await apiPost(`/pins/search`, {
      filter,
      limit: 18,
      page: isNew ? 1 : _.get(pinsListSearch, 'response.nextPage', 1),
      searchString,
      sort,
      worldId,
    });
    return data;
  },
);

export const foldersSearchRequest = createAsyncThunk(
  'pins/foldersSearchRequest',
  async ({isNew}, {getState}) => {
    const {
      inApp: {world: {_id: worldId}},
      pins: {foldersListSearch},
      ui: {txtInputPlacesLists: {textInputField}}
    } = getState();
    const {filter, sort} = foldersListSearch;
    const {data} = await apiPost(`/folders/search`, {
      filter,
      limit: 18,
      page: isNew ? 1 : _.get(foldersListSearch, 'response.nextPage', 1),
      searchString: textInputField,
      sort,
      worldId,
    });
    return data;
  },
);


export const folderDeleteRequest = createAsyncThunk(
  'pins/folderDeleteRequest',
  async (payload, {getState}) => {
    const {
      folderId,
      deletePins,
    } = payload;

    const {data} = await apiPost(`/folders/delete`, {
      folderId,
      deletePins,
    });
    return data;
  },
);

export const pinDeleteRequest = createAsyncThunk(
  'pins/pinDeleteRequest',
  async (payload, {getState}) => {
    const {
      pinId,
    } = payload;

    const {data} = await apiPost(`/pins/delete`, {
      pinId,
    });
    return data;
  },
);

export const pinRatingCreateRequest = createAsyncThunk(
  'pins/pinRatingCreateRequest',
  async (payload, {getState}) => {
    const {
      pinId,
      vote,
    } = payload;

    const {data} = await apiPost(`/ratings/create`, {
      contentId: pinId,
      vote,
    });
    return data;
  },
);
export const pinRatingDeleteRequest = createAsyncThunk(
  'pins/pinRatingDeleteRequest',
  async (payload, {getState}) => {
    const {
      pinId,
    } = payload;

    const {data} = await apiPost(`/ratings/delete`, {
      contentId: pinId,
    });
    return data;
  },
);


export const pinsFolderRequest = createAsyncThunk(
  'pins/pinsFolderRequest',
  async ({isNew, searchString = ''}, {getState}) => {
    const {inApp: {world: {_id: worldId}}, pins: {folderOpened, pinsListFolder}} = getState();
    const {data} = await apiPost(`/pins/search`, {
      folderId: _.get(folderOpened, '_id'),
      limit: 18,
      page: isNew ? 1 : _.get(pinsListFolder, 'response.nextPage', 1),
      searchString,
      sort: pinsListFolder.sort,
      worldId,
    });
    return data;
  },
);

export const foldersListOthersRequest = createAsyncThunk(
  'pins/foldersListOthersRequest',
  async ({
    worldId,
  }) => {
    const {data} = await apiPost(`/folders/foldersListOthers`, {});
    return data;
  },
);

export const foldersListSharedInWorldRequest = createAsyncThunk(
  'pins/foldersListSharedInWorldRequest',
  async ({
    worldId,
  }) => {
    const {data} = await apiPost(`/folders/foldersListSharedInWorld`, {worldId});
    return data;
  },
);

export const foldersListUserRequest = createAsyncThunk(
  'pins/foldersListUserRequest',
  async ({
    worldId,
  }) => {
    const {data} = await apiPost(`/folders/foldersListUser`, {});
    return data;
  },
);

export const pinsSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    folderEditFormSet: (state, {payload}) => {
      state.folderEditForm = payload;
    },
    folderEditFormUpdate: (state, {payload}) => {
      state.folderEditForm = {
        ...state.folderEditForm,
        ...payload,
      };
    },
    folderClose: (state, {payload}) => {
      state.folderOpened = undefined;
    },
    folderOpen: (state, {payload}) => {
      state.folderOpened = payload;
      state.pinsListFolder.response = null;
    },
    foldersSearchFilterSet: (state, {payload}) => {
      state.foldersListSearch.filter = payload;
      state.foldersListSearch.response = null;
    },
    foldersSearchSortSet: (state, {payload}) => {
      state.foldersListSearch.sort = payload;
      state.foldersListSearch.response = null;
    },
    foldersSearchResultsClear: (state, {}) => {
      state.foldersListSearch.isLoading = false;
      state.foldersListSearch.response = null;
    },
    isNewDataAvailableSet: (state, {payload}) => {
      state.isNewDataAvailable = payload;
    },
    pinEditFormSet: (state, {payload}) => {
      state.pinEditForm = payload;
    },
    pinEditFormUpdate: (state, {payload}) => {
      state.pinEditForm = {
        ...state.pinEditForm,
        ...payload,
      };
    },
    pinsListFolderResultsClear: (state, {}) => {
      state.pinsListFolder.isLoading = false;
      state.pinsListFolder.response = null;
    },
    pinsListFolderSortSet: (state, {payload}) => {
      state.pinsListFolder.sort = payload;
      state.pinsListFolder.response = null;
    },
    pinsSearchFilterSet: (state, {payload}) => {
      state.pinsListSearch.filter = payload;
      state.pinsListSearch.response = null;
    },
    pinsSearchResultsClear: (state, {}) => {
      state.pinsListSearch.isLoading = false;
      state.pinsListSearch.response = null;
    },
    pinsSearchSortSet: (state, {payload}) => {
      state.pinsListSearch.sort = payload;
      state.pinsListSearch.response = null;
    },
  },
  extraReducers: (builder) => { // https://redux-toolkit.js.org/api/createAsyncThunk#examples
    addRequestCases(builder, folderEditFormSaveRequest, 'folderEditFormSave', {
      fulfilledReducer: (state, action) => {
        const {folderEditFormSave, folderOpened, foldersListSearch, foldersListUser} = state;
        folderEditFormSave.isLoading = false;
        folderEditFormSave.response = action.payload;

        const updatedFolder = action.payload;

        if (foldersListUser.response) { // update existing folder in foldersListUser or add it to the list if it's new
          const i = _.findIndex(foldersListUser.response, f => f._id === updatedFolder._id);
          if (i >= 0) foldersListUser.response[i] = updatedFolder;
          else foldersListUser.response.unshift(updatedFolder);
        }

        if (foldersListSearch.response) { // update existing folder in foldersListSearch or add it to the list if it's new
          const i = _.findIndex(foldersListSearch.response.folders, f => f._id === updatedFolder._id);
          if (i >= 0) foldersListSearch.response.folders[i] = updatedFolder;
          else foldersListSearch.response.folders.unshift(updatedFolder);
        }

        // if folder drawer is open, update it
        if (folderOpened && folderOpened._id === updatedFolder._id)
          state.folderOpened = updatedFolder;
      }
    });

    addRequestCases(builder, foldersSearchRequest, 'foldersListSearch', {
      fulfilledReducer: (state, action) => {
        const {foldersListSearch} = state;
        foldersListSearch.isLoading = false;

        // response is return val from paginateAggregate with `docs` aliased as `pins`
        // https://github.com/aravindnc/mongoose-aggregate-paginate-v2#modelaggregatepaginateaggregatequery-options-callback
        foldersListSearch.response = {
          ...action.payload,
          folders: [
            ...action.payload.page === 1 ? [] : _.get(foldersListSearch, 'response.folders', []),
            ...action.payload.folders,
          ],
        };
      }
    });

    addRequestCases(builder, pinsBalanceRequest, 'pinsBalanceRequest');

    addRequestCases(builder, pinsFolderRequest, 'pinsListFolder', {
      fulfilledReducer: (state, action) => {
        const {pinsListFolder} = state;
        pinsListFolder.isLoading = false;

        // response is return val from paginateAggregate with `docs` aliased as `pins`
        // https://github.com/aravindnc/mongoose-aggregate-paginate-v2#modelaggregatepaginateaggregatequery-options-callback
        pinsListFolder.response = {
          ...action.payload,
          pins: [
            ...action.payload.page === 1 ? [] : _.get(pinsListFolder, 'response.pins', []),
            ...action.payload.pins,
          ],
        };
      }
    });
    addRequestCases(builder, pinEditFormSaveRequest, 'pinEditFormSave', {
      fulfilledReducer: (state, action) => {
        const {
          folderOpened,
          pinEditFormSave,
          pinsBalanceRequest,
          pinsListFolder,
          pinsListSearch,
        } = state;

        pinEditFormSave.isLoading = false;
        const {pin: updatedPin, pinsBalance} = action.payload;
        pinEditFormSave.response = updatedPin;
        pinsBalanceRequest.response = {pinsBalance};

        // update if in search results
        if (pinsListSearch.response) {
          const i = _.findIndex(pinsListSearch.response.pins, p => p._id === updatedPin._id);
          if (i >= 0) pinsListSearch.response.pins[i] = updatedPin;
        }

        // update if still in folder, remove if moved to different folder
        if (folderOpened && pinsListFolder.response.pins) {
          const i = _.findIndex(pinsListFolder.response.pins, p => p._id === updatedPin._id);
          if (i >= 0) {
            if (folderOpened._id === updatedPin.folderId)
              pinsListFolder.response.pins[i] = updatedPin;
            else pinsListFolder.response.pins.splice(i, 1);
          }
        }
      }
    });
    addRequestCases(builder, pinRatingCreateRequest, 'pinRating', {
      fulfilledReducer: (state, action) => {
        const {pinRating, pinsListFolder, pinsListSearch} = state;
        pinRating.isLoading = false;
        pinRating.response = action.payload;

        const updatedPin = action.payload;

        // update if in search results
        if (_.get(pinsListSearch, 'response.pins')) {
          const i = _.findIndex(pinsListSearch.response.pins, p => p._id === updatedPin._id);
          if (i >= 0) pinsListSearch.response.pins[i] = updatedPin;
        }

        // update if still in folder
        if (_.get(pinsListFolder, 'response.pins')) {
          const i = _.findIndex(pinsListFolder.response.pins, p => p._id === updatedPin._id);
          if (i >= 0) pinsListFolder.response.pins[i] = updatedPin;
        }
      }
    });
    addRequestCases(builder, pinRatingDeleteRequest, 'pinRating', {
      fulfilledReducer: (state, action) => {
        const {pinRating, pinsListFolder, pinsListSearch} = state;
        pinRating.isLoading = false;
        pinRating.response = action.payload;

        const updatedPin = action.payload;

        // update if in search results
        if (_.get(pinsListSearch, 'response.pins')) {
          const i = _.findIndex(pinsListSearch.response.pins, p => p._id === updatedPin._id);
          if (i >= 0) pinsListSearch.response.pins[i] = updatedPin;
        }

        // update if still in folder
        if (_.get(pinsListFolder, 'response.pins')) {
          const i = _.findIndex(pinsListFolder.response.pins, p => p._id === updatedPin._id);
          if (i >= 0) pinsListFolder.response.pins[i] = updatedPin;
        }
      }
    });
    addRequestCases(builder, pinsSearchRequest, 'pinsListSearch', {
      fulfilledReducer: (state, action) => {
        const {pinsListSearch} = state;
        pinsListSearch.isLoading = false;

        // response is return val from paginateAggregate with `docs` aliased as `pins`
        // https://github.com/aravindnc/mongoose-aggregate-paginate-v2#modelaggregatepaginateaggregatequery-options-callback
        pinsListSearch.response = {
          ...action.payload,
          pins: [
            ...action.payload.page === 1 ? [] : _.get(pinsListSearch, 'response.pins', []),
            ...action.payload.pins,
          ],
        };
      }
    });
    addRequestCases(builder, folderDeleteRequest, 'folderDelete', {
      fulfilledReducer: (state, action) => {
        const {folderDelete, foldersListSearch, foldersListUser, folderOpened} = state;
        folderDelete.isLoading = false;
        const {folderId, deletePins} = action.meta.arg;

        {
          // update if in foldersListUser
          if (foldersListUser.response) {
            const i = _.findIndex(foldersListUser.response, f => f._id === folderId);
            if (i >= 0) foldersListUser.response.splice(i, 1);
          }
        }

        if (foldersListSearch.response) { // update if in foldersListSearch
          const i = _.findIndex(foldersListSearch.response.folders, f => f._id === folderId);
          if (i >= 0) foldersListSearch.response.folders.splice(i, 1);
        }

        if (folderOpened) {
          state.folderOpened = undefined;
          if (deletePins) {
            state.pinsListFolder.response = null;
            state.pinsListSearch.response = null;
          }
        }
      }
    });
    addRequestCases(builder, pinDeleteRequest, 'pinDelete', {
      fulfilledReducer: (state, action) => {
        const {pinDelete, pinsListFolder, pinsListSearch} = state;
        pinDelete.isLoading = false;
        const {pinId} = action.meta.arg;

        // update if in search results
        if (_.get(pinsListSearch.response, 'pins')) {
          const i = _.findIndex(pinsListSearch.response.pins, p => p._id === pinId);
          if (i >= 0) pinsListSearch.response.pins.splice(i, 1);
        }

        // update if in folder results
        if (_.get(pinsListFolder.response, 'pins')) {
          const i = _.findIndex(pinsListFolder.response.pins, p => p._id === pinId);
          if (i >= 0) pinsListFolder.response.pins.splice(i, 1);
        }
      }
    });
    addRequestCases(builder, foldersListOthersRequest, 'foldersListOthers');
    addRequestCases(builder, foldersListSharedInWorldRequest, 'foldersListSharedInWorld');
    addRequestCases(builder, foldersListUserRequest, 'foldersListUser');
  }
});

export const {
  folderClose,
  folderEditFormSet,
  folderEditFormUpdate,
  folderOpen,
  foldersSearchFilterSet,
  foldersSearchSortSet,
  foldersSearchResultsClear,
  isNewDataAvailableSet,
  pinEditFormSet,
  pinEditFormUpdate,
  pinsListFolderResultsClear,
  pinsListFolderSortSet,
  pinsSearchFilterSet,
  pinsSearchResultsClear,
  pinsSearchSortSet,
} = pinsSlice.actions;

export default pinsSlice.reducer;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {sendVoiceInputRequest} from '../vuplex';
import {addRequestCases, requestDefaultState} from './reducerUtils';

const initialState = {
  isToolOn: true,
  mapLocation: {
    center: {
      latitude: 0,
      longitude: 0,
    },
    radiusKm: 0,
    zoom: 0,
  },
  panoId: '',
  permissions: {/* permA: 'permA', ... */},
  userId: '',
  voiceInput: requestDefaultState,
  world: { rules: null },
};

export const voiceInputRequest = createAsyncThunk(
  'inApp/voiceInputRequest',
  sendVoiceInputRequest,
);

export const inAppSlice = createSlice({
  name: 'inApp',
  initialState,
  reducers: {
    isToolOnSet: (state, {payload: isOn}) => {
      state.isToolOn = isOn;
    },
    mapLocationSet: (state, {payload: {latitude, longitude, radius, zoom}}) => {
      state.mapLocation = {
        center: {latitude, longitude},
        radiusKm: radius,
        zoom,
      };
    },
    panoIdSet: (state, {payload: panoId}) => {
      state.panoId = panoId;
    },
    permissionsSet: (state, {payload: permissions}) => {
      state.permissions = permissions;
    },
    userIdSet: (state, {payload: userId}) => {
      state.userId = userId;
    },
    voiceInputClear: (state) => {
      state.voiceInput = requestDefaultState;
    },
    worldSet: (state, {payload}) => {
      state.world = payload;
    },
  },
  extraReducers: (builder) => { // https://redux-toolkit.js.org/api/createAsyncThunk#examples
    addRequestCases(builder, voiceInputRequest, 'voiceInput');
  }
});

export const {
  isToolOnSet,
  mapLocationSet,
  panoIdSet,
  permissionsSet,
  userIdSet,
  voiceInputClear,
  worldSet,
} = inAppSlice.actions;

export default inAppSlice.reducer;

import {AbsoluteCenter, Card, CardBody, Center, Stack, StackItem, Text} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import ContentSeparatorCta from '../../components/ContentSeparatorCta';
import HasPermission from '../../components/HasPermission';
import InfiniteListPlaceholder from '../../components/InfiniteListPlaceholder';
import {exploreGoogle, exploreWiki} from '../../hooks/usePermissions';
import {useRetryOnAccessTokenRefresh} from '../../hooks/useRetryOnAccessTokenRefresh';
import {autoCompleteBalanceRequest, placesNearbyBalanceRequest} from '../../reducers/placesReducer';
import {textInputPlaceholderSet} from '../../reducers/uiReducer';
import Birds from './Birds';
import GooglePlaces from './GooglePlaces';
import InputBar from './InputBar';
import NavButtons from './NavButtons';
import PageTitle from './PageTitle';
import UpgradeButton from './UpgradeButton';
import UpgradeWrapper from './UpgradeWrapper';
import Wikipedia from './Wikipedia';

const textInputKey = 'txtInputExplore';

const Explore = () => {
  const autocompleteCredits = useSelector((s) => s.places.autoComplete.credits);
  const canAutocomplete = autocompleteCredits > 0;

  const {
    center: {
      latitude, longitude,
    },
    radiusKm,
  } = useSelector(state => state.inApp.mapLocation);
  const missingLocation = radiusKm === 0 && latitude === 0 && longitude === 0;
  const canExplore = !missingLocation && radiusKm <= 50;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(textInputPlaceholderSet({
      key: textInputKey,
      val: `Search places${autocompleteCredits <= 5 ? ` (${autocompleteCredits} remaining today)` : ''}`
    }));
  }, [autocompleteCredits]);

  useRetryOnAccessTokenRefresh(() => dispatch(autoCompleteBalanceRequest()));
  useRetryOnAccessTokenRefresh(() => dispatch(placesNearbyBalanceRequest()));

  return (
    <>
      <PageTitle title="Explore - Wooorld"/>

      <InputBar
        contentRight={!canAutocomplete && ( // standard has 5 autocomplete. show upgrade message if standard and used up credits
          <HasPermission
            permission={exploreGoogle} // hack to determine they don't have explorer+
            fallbackContent={
              <AbsoluteCenter top="3.7em">
                <UpgradeButton descriptor="Explore InputBar" packageName={'explorer+'}>
                  <Text paddingX={1}>Upgrade to search more</Text>
                </UpgradeButton>
              </AbsoluteCenter>
            }
          />
        )}
        isDisabled={!canAutocomplete}
        stateKey={textInputKey}
      />

      <ContentSeparatorCta>
        or select a data source to explore nearby places
      </ContentSeparatorCta>

      <Stack gap={4} position="relative">
        <HasPermission permission={exploreWiki}>
          <Center>
            <UpgradeWrapper
              descriptor="Explore Google"
              permission={exploreGoogle}
              packageName={'explorer+'}
              position="relative"
              left="-1em"
            >
              <NavButtons
                linkConfigs={[
                  {
                    activeBg: '#B1BEC5',
                    label: 'Off',
                    to: 'none',
                    w: '7em',
                  },
                  {
                    label: 'Wikipedia',
                    to: 'wikipedia',
                    w: '7em',
                  },
                  { // TODO: refresh icon when isPlacesRefresh if (isToolOn && googlePlacesIsRefresh)
                    activeBgGradient: `linear(180deg, #33BBFF, #0080FF)`,
                    permission: exploreGoogle,
                    label: 'Google',
                    to: 'places',
                    w: '7em',
                  },
                  // {
                  //   label: 'Birds',
                  //   to: 'birds',
                  // },
                ]}
              />
            </UpgradeWrapper>
          </Center>
        </HasPermission>

        <StackItem>
          <Routes>
            <Route path="birds/*" element={<Birds/>}/>
            <Route
              path="none" element={
              <InfiniteListPlaceholder>
                <Card bg="none" borderRadius="2xl" h="12em" w="full">
                  <CardBody color="grey" fontSize="lg">
                    <AbsoluteCenter>
                      Select a data source.
                    </AbsoluteCenter>
                  </CardBody>
                </Card>
              </InfiniteListPlaceholder>}
            />
            <Route path="places/*" element={<GooglePlaces/>}/>
            <Route path="wikipedia/*" element={<Wikipedia/>}/>
            <Route path="*" element={<Navigate to="none" replace={true}/>}/>
          </Routes>
        </StackItem>

      </Stack>
    </>
  );
};

export default Explore;

import {Box, Card, CardBody, CardFooter, CardHeader, Flex} from '@chakra-ui/react';
import React, {useState} from 'react';
import {sendAudioHover} from '../../vuplex';
import Landscape from './Landscape';

const WorldCardBlank = ({
  children,
  contentGap,
  contentHeight = '8em',
  headerContent,
  height = '10em',
  hoverContent,
  isHelpHighlighted,
  landscapeHeight = 100,
  landscapeTop = '90px',
  onClick,
  world,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const bg = world.isLobby
    ? '#8dbbdb'
    : world.rules.isWitw
      ? 'playerPink'
      : 'explorerBlue';

  return (
    <>
      <Card
        _hover={{
          filter: `brightness(0.8)`,
        }}
        bg={bg}
        borderRadius="2xl"
        boxShadow={isHelpHighlighted ? '0px 0px 0px 8px #FFF155' : null}
        height={height}
        onClick={onClick}
        onPointerEnter={(e) => {
          sendAudioHover(e);
          setIsHovering(true);
        }}
        onPointerLeave={() => {
          setTimeout(() => setIsHovering(false), 100);
        }}
        overflow="hidden"
        p={0}
      >
        <CardHeader p={1}>
          {headerContent}
        </CardHeader>

        <CardBody p={0} position="relative">
          <Box
            bg={bg}
            position="absolute"
            top={landscapeTop}
            w="full"
          >
            <Landscape
              world={world}
              height={landscapeHeight}
              width="100%"
            />
          </Box>

          <Flex
            flexDirection="column"
            gap={contentGap}
            h={contentHeight}
            justifyContent="space-evenly"
            paddingX={2}
            paddingY={0}
            position="relative"
          >
            {isHovering && hoverContent
              ? hoverContent
              : children}
          </Flex>

        </CardBody>

        <CardFooter p={2}>

        </CardFooter>
      </Card>
    </>
  );


};

export default WorldCardBlank;

import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  roleCreateOrUpdateRequest,
  roleDeleteByIdRequest,
  rolesListRequest
} from '../../../reducers/gooodReducer';
import TableEditor from '../TableEditor';

const RolesTable = () => {
  const {isLoading: isPermsLoading, response: permsResponse} = useSelector(s => s.goood.permissionsAdmin.permissions);
  const {isLoading, response} = useSelector(s => s.goood.permissionsAdmin.roles);
  const roles = response || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(rolesListRequest());
  }, []);

  const permissions = permsResponse || [];
  const permissionOptions = _.sortBy(permissions, 'name').map(p => ({
    label: p.name,
    value: p._id,
  }));

  const {isLoading: isPropsLoading, response: propsResponse} = useSelector(s => s.goood.permissionsAdmin.props);

  const props = propsResponse || [];
  const propsOptions = _.sortBy(props, 'name').map(p => ({
    label: p.name,
    value: p._id,
  }));

  const {isLoading: isBadgesLoading, response: badgesResponse} = useSelector(s => s.goood.permissionsAdmin.badges);
  const badges = badgesResponse || [];
  const badgeOptions = badges.map(p => ({
    label: p.name,
    value: p._id,
  }));

  const badgeLabelsById = _.fromPairs(badges.map(p => [p._id, p.name]));
  const badgeValuesToLabels = values => values && values.map(val => badgeLabelsById[val] || val);

  const propLabelsById = _.fromPairs(props.map(p => [p._id, p.name]));
  const propValuesToLabels = values => values && values.map(val => propLabelsById[val] || val);

  const permissionLabelsById = _.fromPairs(permissions.map(p => [p._id, p.name]));
  const permissionValuesToLabels = values => values && values.map(val => permissionLabelsById[val] || val);

  const budgetToLabel = ({accrualPeriod, amount, creditType}) =>
    `${creditType}×${amount >= Number.MAX_SAFE_INTEGER ? '∞' : amount} (${accrualPeriod ? `${accrualPeriod}` : 'once'})`;

  return (
    <TableEditor
      fields={[
        {
          isRequired: true,
          key: 'name',
          placeholder: 'Role Name',
        },
        {
          key: 'comment',
          placeholder: 'A note about this role.',
        },
        {
          inputType: 'skusEditor',
          key: 'skuOculus',
          placeholder: 'sku',
        },
        {
          inputType: 'skusEditor',
          key: 'skuOculusConsumable',
          placeholder: 'sku',
        },
        {
          inputType: 'budgetsEditor',
          key: 'budgets',
          valToLabel: budgets => _.map(budgets, budgetToLabel),
        },
        {
          inputType: 'multiselect',
          key: 'permissionsGranted',
          options: permissionOptions,
          valToLabel: permissionValuesToLabels,
        },
        {
          inputType: 'multiselect',
          key: 'permissionsDenied',
          options: permissionOptions,
          valToLabel: permissionValuesToLabels,
        },
        {
          inputType: 'multiselect',
          key: 'badges',
          options: badgeOptions,
          valToLabel: badgeValuesToLabels,
        },
        {
          inputType: 'multiselect',
          key: 'props',
          options: propsOptions,
          valToLabel: propValuesToLabels,
        },
      ]}
      isLoading={isLoading || isPermsLoading || isBadgesLoading || isPropsLoading}
      items={roles}
      onCreateOrUpdate={roleCreateOrUpdateRequest}
      onDelete={roleDeleteByIdRequest}
      title="Roles"
    />);
};

export default RolesTable;

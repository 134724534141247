module.exports = {
  goood: {
    tours: {
      filters: {
        complete: 'complete',
        createdByUser: 'createdByUser',
        deleted: 'deleted',
        likedByUser: 'likedByUser',
      }
    },
    users: {
      filters: {
        alphaTester: 'alphaTester',
        explorer: 'explorer',
        founder: 'founder',
        isDev: 'isDev',
        online: 'online',
        player: 'player',
      }
    },
    worlds: {
      filters: {
      },
      sorts: {
        oldest: 'oldest',
        newest: 'newest',
        userCount: 'userCount',
      }
    }
  }
}

import {SmallAddIcon, SmallCloseIcon} from '@chakra-ui/icons';
import {Box, IconButton} from '@chakra-ui/react';
import React from 'react';
import {MdOutlineVrpano} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {tourSetPanoIdRequest} from '../../reducers/toursReducer';
import {sendAudioClick} from '../../vuplex';
import InAppTooltip from './InAppTooltip';

const PanoSetButton = ({
  isEditing,
  onSaved,
  panoId,
  placeIndex,
  promptId,
}) => {
  const dispatch = useDispatch();
  const {panoId: inAppPanoId} = useSelector(state => state.inApp);
  const {isLoading} = useSelector(state => state.tours.tourSetPanoId);

  const isSet = !!panoId;

  const handleClick = (evt) => {
    sendAudioClick(evt);
    dispatch(tourSetPanoIdRequest({
      panoId: isSet ? '' : inAppPanoId,
      placeIndex,
      promptId,
    })).then(() => {
      onSaved();
    });
  };

  const canSet = inAppPanoId && !isSet;

  const tooltip = isSet
      ? `Remove this stop's 360`
      : !inAppPanoId
        ? `First, load a 360 then click here to assign it to this stop`
        : `Assign current 360 to this stop`;

  return (
    <InAppTooltip message={tooltip}>
      <Box position="relative">
        <IconButton
          colorScheme={isSet ? 'wooorldBlue' : null}
          icon={<MdOutlineVrpano size="1.5em"/>}
          isDisabled={!isEditing && !canSet}
          isLoading={isLoading}
          isRound
          onClick={handleClick}
        />
        {canSet ? (
          <SmallAddIcon
            bg="wooorldBlue._"
            borderRadius="full"
            color="white"
            position="absolute"
            right="-0.2em"
            top="-0.2em"
          />
        )
          : (isEditing && isSet) && (
            <SmallCloseIcon
              background="red"
              borderRadius="full"
              color="white"
              pointerEvents="none"
              position="absolute"
              right="-0.2em"
              top="-0.2em"
            />
          )}
      </Box>
    </InAppTooltip>
  );
};

export default PanoSetButton;

import {Flex, IconButton, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useState} from 'react';
import {MdOutlineArrowBack, MdOutlineArrowForward, MdSportsScore} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {textToSpeechStatusSet} from '../../reducers/uiReducer';
import {sendAudioClick, sendNavTo, sendPanoUnload, sendTextToSpeech, sendTextToSpeechStop} from '../../vuplex';

const TourStopFooter = () => {
  const {promptId, placeIndex: placeIndexParam} = useParams();
  const placeIndex = parseInt(placeIndexParam, 10);
  const {panoId: inAppPanoId} = useSelector(state => state.inApp);

  const [isLoaded, setIsLoaded] = useState(false);

  const {
    completion,
    tourPrompt,
  } = useSelector(({tours}) => tours.prompt.response) || {};

  const tourStop = _.get(completion, `json.places.${placeIndex}`) || {};

  const {
    address,
    coordinates: [{lat, lon}] = [{}],
    description,
    googleCoordinates,
    googlePlace,
    name,
    panoId,
    pinCoordinates,
    wikiCoordinates,
    wikiPageId,
    wikiSearch,
    wikiSummary,
    wikiThumbnail,
  } = tourStop || {};
  const {isOwnedByUser} = tourPrompt || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleThumbnail = _.get(googlePlace, 'photos.0.photoUrl');
  const thumbnail = _.get(wikiThumbnail, 'source') || googleThumbnail;


  const tourStopNext = _.get(completion, `json.places.${placeIndex + 1}`);
  const tourStopPrev = _.get(completion, `json.places.${placeIndex - 1}`);
  const tourStopCount = _.get(completion, `json.places.length`);

  const handleNextPrevClick = (tourStopClicked, addToIndex) => (e) => {
    sendAudioClick(e);

    if (!tourStopClicked) {
      navigate(-1);
      sendPanoUnload();
      return;
    }

    const placeIndexClicked = placeIndex + addToIndex;
    navigate(`/wooorld/tablet/tours/tour/${tourPrompt._id}/${placeIndexClicked}`, {replace: true});

    dispatch(textToSpeechStatusSet('pending'));
    sendTextToSpeechStop();
    sendTextToSpeech(`stop number ${placeIndexClicked + 1}. ${tourStopClicked.name}. ${tourStopClicked.description}`);

    if (tourStopClicked.panoId !== inAppPanoId) sendPanoUnload();

    if (tourStopClicked.pinCoordinates) {
      sendNavTo({
        ...tourStopClicked.pinCoordinates,
        panoId: tourStopClicked.panoId,
      });
    }
  };


  return (
    <Flex
      bottom={2}
      direction="row"
      gap={2}
      position="sticky"
      justifyContent="center"
      width="full"
      zIndex="sticky"
    >
      <Flex
        alignItems="center"
        bg="black24"
        borderRadius="3xl"
        gap={2}
      >
        <IconButton
          border="solid 2px #3D3D3D"
          icon={<MdOutlineArrowBack size="1.5em"/>}
          isDisabled={!tourStopPrev}
          isRound
          onClick={handleNextPrevClick(tourStopPrev, -1)}
          variant="buttonMinor"
          zIndex={2}
        />
        <Text color="white" whiteSpace="nowrap">Stop {placeIndex + 1}/{tourStopCount}</Text>
        <IconButton
          border="solid 2px #3D3D3D"
          shadow="lg"
          icon={!tourStopNext ? <MdSportsScore size="1.5em"/> : <MdOutlineArrowForward size="1.5em"/>}
          isRound
          onClick={handleNextPrevClick(tourStopNext, 1)}
          variant="buttonMinor"
          zIndex={2}
        />
      </Flex>
    </Flex>
  );
};

export default TourStopFooter;

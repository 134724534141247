import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {FaFilter} from 'react-icons/fa';
import {useLocation} from 'react-router-dom';
import {sendAudioClick, sendAudioHover} from '../../vuplex';
import InAppTooltip from './InAppTooltip';

const Filters = ({children, isActive}) => {
  const {isOpen, onToggle, onClose} = useDisclosure();

  // hack to close popover when navigating away
  const {pathname} = useLocation();
  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <Popover
      computePositionOnMount
      isOpen={isOpen}
      onClose={onClose}
      strategy="fixed"
    >
      <InAppTooltip message="Filter results">
        <PopoverTrigger>
          <IconButton
            color={isActive ? 'white' : null}
            bg={isActive ? "wooorldBlue._" : null}
            icon={<FaFilter/>}
            isRound
            onClick={(evt) => {
              sendAudioClick(evt);
              onToggle();
            }}
            onPointerEnter={(e) => {
              sendAudioHover(e);
            }}
            variant="buttonMinor"
          />
        </PopoverTrigger>
      </InAppTooltip>

      <PopoverContent bg="#666" border="none" m={2} w="10em" shadow="dark-lg" borderRadius="2xl" >
        <PopoverArrow bg="#666"/>
        <PopoverBody>
          <Stack onClick={onClose}>
            {children}
          </Stack>
        </PopoverBody>
      </PopoverContent>

    </Popover>
  )
}

export default Filters;

import {
  Code,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading
} from '@chakra-ui/react';
import React from 'react';


const DevConsoleCallStackDrawer = ({entry, onClose}) => {
  const {LogType, Message, StackTrace, ix} = entry || {};

  return (
    <Drawer
      isOpen={!!entry}
      placement="right"
      onClose={onClose}
      size="lg"
      // finalFocusRef={} // The ref of element to receive focus when the modal closes.
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton outline="solid" />
        <DrawerHeader
          bg={{
            Error: '#FAA',
            Log: '#AEF',
            Warning: '#FEA'
          }[LogType]}
        >
          <Heading size="sm" overflow="clip">[{ix}]-> {Message}</Heading>
        </DrawerHeader>

        <DrawerBody
          p={1}
        >
          <Code
            // bg="none"
            // maxH="50vh"
            whiteSpace="pre"
          >
            {StackTrace}
          </Code>
        </DrawerBody>

        {/*<DrawerFooter>*/}
        {/*</DrawerFooter>*/}
      </DrawerContent>
    </Drawer>
  );


};

export default DevConsoleCallStackDrawer;

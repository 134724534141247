import {Flex, Heading, Skeleton, Table, TableContainer, Tbody, Tfoot, Th, Thead, Tr} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import TableRowDisplay from './TableRowDisplay';
import TableRowEdit from './TableRowEdit';

const TableEditor = ({
  fields,
  isLoading,
  items,
  onCreateOrUpdate,
  onDelete,
  title,
}) => {
  const [editingId, setEditingId] = useState(null);
  const dispatch = useDispatch();

  return (
    <Skeleton isLoaded={!isLoading} w="full">
      <Flex
        bgGradient="linear(to-b, #DADADA, wooorldLavender.100)"
        borderRadius="lg"
        flexWrap="wrap"
        gap={1}
        padding={2}
        position="relative"
        w="full"
      >
        <Heading size="sm" w="full">{title}</Heading>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                {fields.map(({key}) => (<Th key={key} textTransform="none">{key}</Th>))}
                <Th></Th>
              </Tr>
            </Thead>

            <Tbody>
              {items.map(p => (
                p._id === editingId
                  ? (
                    <TableRowEdit
                      fields={fields}
                      item={p}
                      key={p._id}
                      onCancel={() => setEditingId(null)}
                      onSubmit={(form) => {
                        setEditingId(null);
                        dispatch(onCreateOrUpdate(form));
                      }}
                    />
                  )
                  : (
                    <TableRowDisplay
                      fields={fields}
                      item={p}
                      key={p._id}
                      onDeleteClick={onDelete && ((id) => { dispatch(onDelete(id))})}
                      onEditClick={setEditingId}
                    />
                  )
              ))}
            </Tbody>

            <Tfoot>
              <TableRowEdit
                fields={fields}
                onSubmit={(form) => {
                  dispatch(onCreateOrUpdate(form));
                }}
              />
            </Tfoot>
          </Table>
        </TableContainer>
      </Flex>
    </Skeleton>
  );
}

export default TableEditor;

import {Box} from '@chakra-ui/react';
import React from 'react';

const ToolPanelMask = ({borderHeight, borderWidth}) => (
  <Box // This is a mask to match the round corners of Unity ToolPanel
    borderColor="bg"
    borderRadius="40px"
    borderWidth="18px"
    h={`${100 + borderHeight * 2}vh`}
    left={`${-borderWidth}vw`}
    p={0}
    pointerEvents="none"
    position="fixed"
    top={`${-borderHeight}vh`}
    w={`${100 + borderWidth * 2}vw`}
    zIndex="tooltip"
  />
);

export default ToolPanelMask;

import {SmallAddIcon, SmallCloseIcon} from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {FaCrosshairs, FaGoogle, FaWikipediaW} from 'react-icons/fa';
import {GrMap} from 'react-icons/gr';
import {MdPlace} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {tourStopCoordinatesSetRequest} from '../../reducers/toursReducer';
import {sendAudioClick, sendNavTo} from '../../vuplex';
import InAppTooltip from './InAppTooltip';

const goog = 'goog';
const mapCenter = 'mapCenter';
const wiki = 'wiki';

const LocSelectButton = ({
  icon,
  labelSelected,
  labelUnselected,
  selection,
  selectionKey,
  setSelection,
}) => {
  const isSelected = selectionKey === selection;
  return (
    <Button
      bg={isSelected ? null : 'white'}
      colorScheme={isSelected ? 'wooorldBlue' : null}
      fontSize="sm"
      leftIcon={icon}
      onClick={(evt) => {
        sendAudioClick(evt);
        if (!isSelected) {
          setSelection(selectionKey);
        } else {
          setSelection(undefined);
        }
      }}
    >
      {isSelected ? labelSelected : labelUnselected}

    </Button>
  );
};

const MapNavAssignButton = ({
  googleCoordinates,
  isEditing,
  onSaved,
  pinCoordinates,
  placeIndex,
  promptId,
  wikiCoordinates,
}) => {
  const {isOpen, onToggle, onClose} = useDisclosure();
  const [selection, setSelection] = useState();
  const dispatch = useDispatch();

  const {
    center: {latitude, longitude},
    zoom,
  } = useSelector(s => s.inApp.mapLocation);

  const isSaving = useSelector(s => s.tours.tourStopCoordinatesSet.isLoading);

  const tooltip = !isEditing
    ? 'Click "Edit" to set the map location of this stop'
    : pinCoordinates
      ? `Edit the map location of this stop`
      : `Choose a map location for this stop`;

  useEffect(() => {
    if (selection === goog) sendNavTo(googleCoordinates);
    if (selection === wiki) sendNavTo(wikiCoordinates);
  }, [selection]);

  const handleSaveClick = (evt) => {
    sendAudioClick(evt);
    dispatch(tourStopCoordinatesSetRequest({
      promptId,
      placeIndex,
      pinCoordinates: {
        [goog]: googleCoordinates,
        [wiki]: wikiCoordinates,
        [mapCenter]: {
          lat: latitude,
          lon: longitude,
          zoom,
        },
      }[selection],
    })).then(() => {
      onClose();
      onSaved();
    });
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box position="relative">
        <InAppTooltip message={tooltip}>
          <PopoverTrigger>
            <IconButton
              colorScheme={pinCoordinates && !isOpen ? 'wooorldBlue' : null}
              icon={<MdPlace size="1.5em"/>}
              isDisabled={!isEditing}
              isRound
              onClick={(evt) => {
                sendAudioClick(evt);
                onToggle();
              }}
            />
          </PopoverTrigger>
        </InAppTooltip>


        {isOpen || !pinCoordinates
          ? isEditing && (
          <SmallAddIcon
            background="wooorldBlue._"
            borderRadius="full"
            color="white"
            position="absolute"
            right="-0.2em"
            top="-0.2em"
          />
        )
          : (
            <SmallCloseIcon
              background="red"
              borderRadius="full"
              color="white"
              pointerEvents="none"
              position="absolute"
              right="-0.2em"
              top="-0.2em"
            />
          )}

      </Box>

      <PopoverContent bg="bg">
        <PopoverArrow bg="bg"/>
        <PopoverCloseButton/>
        <PopoverBody>
          <Stack gap={2}>
            {wikiCoordinates && (
              <LocSelectButton
                icon={<FaWikipediaW/>}
                labelSelected="Using Wikipedia recommended location"
                labelUnselected="Go to Wikipedia recommended location"
                selection={selection}
                selectionKey={wiki}
                setSelection={setSelection}
              />
            )}

            {googleCoordinates && (
              <LocSelectButton
                icon={<FaGoogle/>}
                labelSelected="Using Google recommended location"
                labelUnselected="Go to Google recommended location"
                selection={selection}
                selectionKey={goog}
                setSelection={setSelection}
              />
            )}

            <LocSelectButton
              icon={<FaCrosshairs/>}
              labelSelected="Using current map center as location"
              labelUnselected="Use current map center as location"
              selection={selection}
              selectionKey={mapCenter}
              setSelection={setSelection}
            />

            {selection
              ? (
                <ButtonGroup>
                  <Button
                    onClick={(evt) => {
                      sendAudioClick(evt);
                      setSelection(undefined);
                    }}
                  >Cancel</Button>
                  <Button
                    colorScheme="wooorldBlue"
                    isLoading={isSaving}
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                </ButtonGroup>)
              : <Button
                onClick={(evt) => {
                  sendAudioClick(evt);
                  onToggle();
                }}
              >Cancel</Button>}

          </Stack>

        </PopoverBody>
      </PopoverContent>
    </Popover>


  );
};

export default MapNavAssignButton;

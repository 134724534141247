import React from 'react';
import {useInAppTooltip} from '../../hooks/useInAppTooltip';

const InAppTooltip = ({children, message}) => {
  const tooltipProps = useInAppTooltip(message);

  return (
    <span {...tooltipProps}>
      {children}
    </span>
  );
};

export default InAppTooltip;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteList from '../../components/InfiniteList';
import {folderOpen} from '../../reducers/pinsReducer';
import {textInputPlaceholderSet} from '../../reducers/uiReducer';
import PinFolderCard from './PinFolderCard';

const columns = 4;

// noinspection JSCheckFunctionSignatures
const PinFoldersSearchList = ({
  onRequestItems,
  stateKey,
}) => {
  const dispatch = useDispatch();

  const isUserFoldersOnly = useSelector(s => s.pins.foldersListSearch.filter) === 'createdByUser';
  const textInputField = useSelector(s => s.ui.txtInputPlacesLists.textInputField);
  const isFiltered = isUserFoldersOnly || textInputField;

  const {
    error,
    isLoading,
    response: searchResponse,
  } = useSelector(s => s.pins[stateKey]);

  useEffect(() => {
    dispatch(textInputPlaceholderSet({key: 'txtInputPlacesLists', val: 'Search lists'}));
  }, []);

  const {hasNextPage, page, folders} = searchResponse || {};

  return (
    <InfiniteList
      {...{
        columns,
        error,
        isFiltered,
        isLoading,
        hasNextPage,
        page,
        items: folders,
        itemHeight: '8em',
      }}
      onFetchNextPage={() => dispatch(onRequestItems({
        isNew: !hasNextPage,
        searchString: textInputField
      }))}
      renderItem={(folder) =>
        <PinFolderCard
          key={folder._id}
          folder={folder}
          onClick={() => {
            dispatch(folderOpen(folder));
          }}
          width="unset"
        />}
    />
  );
};

export default PinFoldersSearchList;

import {ChevronDownIcon} from '@chakra-ui/icons';
import {
  Center,
  Heading,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  StackItem,
  Text
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {tripPlannerFormUpdate} from '../../../reducers/toursReducer';

const NumberField = ({
  label,
  min = 0,
  max = 20,
  onChange,
  value,
  ...others
}) => (
  <StackItem>
    <Text>{label}</Text>
    <Center>
      <NumberInput
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        {...others}
      >
        <NumberInputField bg="white"/>
        <NumberInputStepper>
          <NumberIncrementStepper/>
          <NumberDecrementStepper/>
        </NumberInputStepper>
      </NumberInput>
    </Center>
  </StackItem>
);

const Step2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateNumber = (key) => (s, n) => {
    dispatch(tripPlannerFormUpdate({[key]: n}));
  };

  const {budgetDollars, days, travelerCount} = useSelector(({tours}) => tours.tripPlanner.form);

  useEffect(() => {
    if (_.every([budgetDollars, days, travelerCount], _.isUndefined))
      dispatch(tripPlannerFormUpdate({
        budgetDollars: 500,
        days: 1,
        travelerCount: 1,
      }));
  }, [budgetDollars, days, travelerCount]);

  return (
    <Stack
      bgGradient="linear(to-b, #C3F1FF, #B6ECC5)"
      borderRadius="lg"
      p={5}
      spacing={6}
      textAlign="center"
    >
      <Heading p={4} size="md">Step 2/3 Numbers</Heading>

      <NumberField
        label="How many travelers?"
        onChange={updateNumber('travelerCount')}
        value={travelerCount}
      />

      <NumberField
        isDisabled
        label="How many days are you staying?"
        onChange={updateNumber('days')}
        max={1}
        min={1}
        value={days}
      />

      <NumberField
        format={(s) => `$${s}`}
        label="What's your budget?"
        max={10000}
        min={0}
        onChange={updateNumber('budgetDollars')}
        step={100}
        value={budgetDollars}
      />

      <Center>
        <IconButton
          colorScheme="wooorldBlue"
          icon={<ChevronDownIcon boxSize="2em"/>}
          isDisabled={false}
          isRound
          onClick={() => navigate('../3')}
        />
      </Center>
    </Stack>);
};

export default Step2;

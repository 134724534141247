import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import FoldersView from './FoldersView';
import PageTitle from './PageTitle';
import PinsFoldersToggle from './PinsFoldersToggle';
import PinsView from './PinsView';
import PinsVuplex from './PinsVuplex';

const Pins = ({}) =>
  (
    <>
      <PageTitle title="Places - Wooorld"/>

      <PinsFoldersToggle/>

      <Routes>
        <Route path="/pins/*" element={<PinsView/>}/>
        <Route path="/folders/*" element={<FoldersView/>}/>
        <Route path="*" element={<Navigate to="pins" replace={true}/>}/>
      </Routes>
      <PinsVuplex/>
    </>
  );

export default Pins;

import {useEffect} from 'react';
import {getVuplexMessageHandlers} from '../vuplex';

export const useVuplex = (messageHandlers, deps = []) => {
  useEffect(() => {
    if (!window.vuplex) return; // for embedding app components in Goood

    const handleVuplexMessage = getVuplexMessageHandlers(messageHandlers);
    window.vuplex.addEventListener('message', handleVuplexMessage);
    return () => window.vuplex.removeEventListener('message', handleVuplexMessage);
  }, deps);
};

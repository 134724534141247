import {Card, CardBody, CardHeader, Flex, Heading, Input, useToast} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {userMessageRequest} from '../../reducers/gooodReducer';
import SafetyButton from './SafetyButton';

const UserMessage = ({
  onSent = () => {
  },
  user: {_id, alias}
}) => {
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  const {isLoading} = useSelector(s => s.goood.broadcast);

  const sendMsg = () => {
    dispatch(userMessageRequest({message: msg, userId: _id}))
      .then(({error}) => {
        toast(error
          ? {
            description: error.message,
            status: 'error',
            title: 'Message failed.',
          }
          : {
            description: msg,
            status: 'success',
            title: `Message sent to ${alias}.`,
          });
        onSent();
      });
  };

  return (
    <Card size="sm">
      <CardHeader>
        <Heading size="sm">
          Send a message to {alias}
        </Heading>
      </CardHeader>
      <CardBody>
        <Flex gap={2} flexWrap="wrap">
          <Input maxW="md" onChange={({target: {value}}) => setMsg(value)} value={msg}/>
          <SafetyButton
            isLoading={isLoading}
            isDisabled={msg.length < 5}
            onClick={sendMsg}
          />
        </Flex>
      </CardBody>
    </Card>
  );
};

export default UserMessage;

import {SmallCloseIcon} from '@chakra-ui/icons';
import {Box, IconButton} from '@chakra-ui/react';
import React from 'react';
import {MdOutlineVrpano} from 'react-icons/md';
import {useSelector} from 'react-redux';
import {sendAudioClick, sendPanoLoad, sendPanoUnload} from '../../vuplex';
import InAppTooltip from './InAppTooltip';

const PanoButton = ({
  disallowUnload,
  isPanoIdSetByAi,
  panoId,
  size,
  tooltip,
}) => {
  const {panoId: inAppPanoId} = useSelector(state => state.inApp);
  const isPanoLoaded = panoId === inAppPanoId;

  const canUnloadPano = isPanoLoaded && !disallowUnload;

  const handleClick = (evt) => {
    sendAudioClick(evt);
    if (canUnloadPano) sendPanoUnload();
    else sendPanoLoad({panoId});
  };

  return panoId && (
    <InAppTooltip
      message={canUnloadPano ? 'Unload current 360' : isPanoLoaded ? 'This 360 is currently loaded' : tooltip}
      position="relative"
    >
      <Box position="relative">

        <IconButton
          border="solid 1px"
          borderColor={isPanoIdSetByAi
            ? '#FF85A2'
            : panoId
              ? '#33bbff'
              : '#BFBFBF'}
          icon={<MdOutlineVrpano size="1.5em"/>}
          isRound
          onClick={handleClick}
          size={size}
          variant="buttonMinor"
        />
        {canUnloadPano && !disallowUnload && (
          <SmallCloseIcon
            background="red"
            borderRadius="full"
            color="white"
            pointerEvents="none"
            position="absolute"
            right="-0.2em"
            top="-0.2em"
          />
        )}
      </Box>
    </InAppTooltip>
  );
};

export default PanoButton;

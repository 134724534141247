import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  permissionCreateOrUpdateRequest,
  permissionDeleteByIdRequest,
  permissionsListRequest
} from '../../../reducers/gooodReducer';
import TableEditor from '../TableEditor';

const PermissionsTable = () => {
  const {isLoading, response} = useSelector(s => s.goood.permissionsAdmin.permissions);
  const items = response || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(permissionsListRequest());
  }, []);

  return (
    <TableEditor
      fields={[
        {
          isRequired: true,
          key: 'name',
          placeholder: 'permissionName',
        },
        {
          key: 'comment',
          placeholder: 'A note about this permission.',
        },
      ]}
      isLoading={isLoading}
      items={_.sortBy(items, 'name')}
      onCreateOrUpdate={permissionCreateOrUpdateRequest}
      onDelete={permissionDeleteByIdRequest}
      title="Permissions"
    />);
};

export default PermissionsTable;

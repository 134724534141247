import {
  Button, Center,
  Code,
  Flex,
  Heading,
  Input, NumberDecrementStepper,
  NumberIncrementStepper, NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select, Tag, useToast
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  creditBalanceRequest,
  creditCreateOrUpdateRequest,
  creditDeleteByIdRequest,
  creditsAccrualRun,
  creditsListRequest, creditsRolesGrant, creditsSpendRequest, userGetRequest
} from '../../../reducers/gooodReducer';
import TableEditor from '../TableEditor';


const creditTypeOptions = [
  'searchNearbyGoogle',
  'searchAutocompleteGoogle',
  'tourCreate',
  'pins',
  'playBoosted',
  'playStandard',
].map(ct => ({label: ct, value: ct}));


const sourceOptions = [
  'accrual',
  'adminGift',
  'aiCompletion',
  'consumable',
  'grant',
  'reward',
  // 'spend',
].map(ct => ({label: ct, value: ct}));


const CreditsTable = () => {
  const {isLoading, response} = useSelector(s => s.goood.permissionsAdmin.credits);
  const credits = response || [];
  const dispatch = useDispatch();

  const {
    isLoading: isLoadingCreditBalance,
    response: creditBalance
  } = useSelector(s => s.goood.permissionsAdmin.creditBalance);
  const {isLoading: isLoadingUsers, response: users} = useSelector(s => s.goood.permissionsAdmin.users);
  const {isLoading: isLoadingRoles, response: rolesResponse} = useSelector(s => s.goood.permissionsAdmin.roles);
  const roles = rolesResponse || [];


  // const budgetsToLabels = budgets =>
  //   budgets && budgets.map(({ accrual, amount, creditType }) =>
  //     `${amount}×${creditType} (${accrual ? `accrues ${accrual}` : 'one time'})`);

  const userOptions = (users || []).map(({alias, _id}) => ({
    label: alias,
    value: _id,
  }));


  const [userId, setUserId] = useState();
  const [accrualDate, setAccrualDate] = useState();
  const [spendAmount, setSpendAmount] = useState();
  const [spendDate, setSpendDate] = useState();
  const [spendCreditType, setSpendCreditType] = useState();
  const toast = useToast();

  const creditValToLabel = v => v >= Number.MAX_SAFE_INTEGER ? '∞' : v;

  useEffect(() => {
    if (!userId) return;
    dispatch(creditsListRequest(userId && {userId}));
    if (userId) dispatch(userGetRequest(userId));

  }, [userId]);

  return (
    <>
      <Flex>

        <Flex bg="cyan.100" flexDirection="column" gap={2}>

          <Select
            colorScheme="wooorldBlue"
            onChange={({target: {value}}) => setUserId(value)}
            placeholder={'pick one'}
            title={'user'}
            value={userId}
          >
            {userOptions.map(({label, value}) => (
              <option value={value}>{label}</option>
            ))}
          </Select>

          <Flex bg="green.100">
            <Flex flexDirection="column" p={4} gap={2}>
              {_.keys(creditBalance).sort().map(k => <Tag key={k}>{k}: {creditValToLabel(creditBalance[k])}</Tag>)}
            </Flex>
            <Center>
              <Button
                isDisabled={!userId}
                isLoading={isLoadingCreditBalance}
                onClick={() => {
                  dispatch(creditBalanceRequest({userId}));
                }}
              >
                Check Balance
              </Button>
            </Center>

          </Flex>

          <Flex bg="blue.100">
            <Flex>
              <Select
                colorScheme="wooorldBlue"
                onChange={({target: {value}}) => setSpendCreditType(value)}
                placeholder={'pick one'}
                title={'creditType'}
                value={spendCreditType}
              >
                {creditTypeOptions.map(({label, value}) => (
                  <option value={value}>{label}</option>
                ))}
              </Select>

              <NumberInput
                colorScheme="wooorldBlue"
                defaultValue={0}
                onChange={(val) => setSpendAmount(val)}
                step={1}
                type="number"
                value={spendAmount}
              >
                <NumberInputField/>
                <NumberInputStepper>
                  <NumberIncrementStepper/>
                  <NumberDecrementStepper/>
                </NumberInputStepper>
              </NumberInput>
              {/*<Input*/}
              {/*  p={1}*/}
              {/*  colorScheme="wooorldBlue"*/}
              {/*  onChange={({target: {value}}) => setSpendDate(value)}*/}
              {/*  size="md"*/}
              {/*  type="datetime-local"*/}
              {/*  value={spendDate}*/}
              {/*/>*/}
            </Flex>

            <Button
              isDisabled={!userId || !spendAmount || !spendCreditType}
              onClick={() => {
                dispatch(creditsSpendRequest({amount: spendAmount, creditType: spendCreditType, userId}))
                  .then(({error}) => {
                    if (error) {
                      toast({
                        description: '',
                        status: 'error',
                        title: 'Insufficient funds',
                      });
                    }
                  });
              }}
            >
              Spend
            </Button>
          </Flex>


          <Button
            isDisabled={!userId}
            onClick={() => {
              dispatch(creditsRolesGrant({userId}));
            }}
          >
            Grant from Roles
          </Button>


          <Flex bg="orange.100">
            <Input
              p={1}
              colorScheme="wooorldBlue"
              onChange={({target: {value}}) => setAccrualDate(value)}
              size="md"
              type="datetime-local"
              value={accrualDate}
            />
            <Button
              isDisabled={!userId || !accrualDate}
              onClick={() => {
                dispatch(creditsAccrualRun({date: accrualDate, userId}));
              }}
            >
              Run Accrual
            </Button>

          </Flex>


        </Flex>


      </Flex>

      <TableEditor
        fields={[
          {
            inputType: 'select',
            isRequired: true,
            key: 'creditType',
            options: creditTypeOptions,
            valToLabel: v => v,
          },
          {
            inputType: 'number',
            isRequired: true,
            key: 'amount',
            placeholder: 'A note about this credit.',
            valToLabel: creditValToLabel,
          },
          {
            inputType: 'number',
            isRequired: true,
            key: 'amountRemaining',
            placeholder: 'A note about this credit.',
            valToLabel: creditValToLabel,
          },
          {
            isReadOnly: true,
            key: 'isVoid',
            valToLabel: v => v ? '❌' : '',
          },
          {
            isReadOnly: true,
            key: 'voidedReason',
          },
          {
            inputType: 'dateTime',
            key: 'voidedOn',
            isReadOnly: true,
          },
          {
            inputType: 'dateTime',
            key: 'expiresOn',
            placeholder: 'select a date and time',
          },
          {
            inputType: 'select',
            isRequired: true,
            key: 'source',
            options: sourceOptions,
            valToLabel: v => v,
          },
          {
            isReadOnly: true,
            key: 'sourceBudgetDescription',
          },
          {
            isReadOnly: true,
            key: 'sourceRole',
            valToLabel: v => _.get(roles.find(r => r._id === v), 'name'),
          },
          {
            inputType: 'select',
            isRequired: true,
            key: 'userId',
            options: userOptions,
            valToLabel: v => _.get(userOptions.find(u => u.value === v), 'label', v),
          },
        ]}
        isLoading={isLoading || isLoadingUsers || isLoadingRoles}
        items={credits}
        onCreateOrUpdate={creditCreateOrUpdateRequest}
        onDelete={creditDeleteByIdRequest}
        title="credits"
      />
    </>
  );
};

export default CreditsTable;

import {Badge, Card, CardBody, CardHeader, Code, Skeleton, Tag} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  badgeCreateOrUpdateRequest,
  badgeDeleteByIdRequest,
  badgesListRequest
} from '../../../reducers/gooodReducer';
import TableEditor from '../TableEditor';

const BadgesTable = () => {
  const {isLoadingUser, response: user} = useSelector(s => s.goood.permissionsAdmin.user);
  const {isLoading, response} = useSelector(s => s.goood.permissionsAdmin.badges);
  const items = response || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(badgesListRequest());
  }, []);

  return (
    <>
      <Skeleton isLoaded={!isLoadingUser}>
        {user && (
          <Card w="20em">
            <CardHeader>{user.alias}'s Badges</CardHeader>
            <CardBody display="flex" gap={2}>
              {user.badges.map(b => (<Tag key={b}>{b}</Tag>))}
            </CardBody>
          </Card>
        )}

      </Skeleton>
      <TableEditor
        fields={[
          {
            isRequired: true,
            key: 'name',
            placeholder: 'badgeName',
          },
          {
            key: 'comment',
            placeholder: 'A note about this badge.',
          },
        ]}
        isLoading={isLoading}
        items={items}
        onCreateOrUpdate={badgeCreateOrUpdateRequest}
        onDelete={badgeDeleteByIdRequest}
        title="Badges"
      />
    </>
  );
};

export default BadgesTable;

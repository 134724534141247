import {Avatar, Card, CardBody, CardFooter, CardHeader, Flex, Heading, Text} from '@chakra-ui/react';
import React, {useState} from 'react';
import {MdCheckCircle, MdOutlineCheckCircle, MdThumbDown, MdThumbUp} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {sendAudioClick, sendAudioHover} from '../../vuplex';
import InAppTooltip from './InAppTooltip';

const TourPromptCard = ({
  _id,
  alias,
  createdOn,
  isDislikedByUser,
  isLikedByUser,
  isOwnedByUser,
  likesCount,
  ownerId,
  ownerActivityStatus,
  panoCount = 0,
  profilePhotoURL,
  prompt: originalPrompt,
  promptInterpreted,
  reactions,
  tags = [],
}) => {

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Link onClick={sendAudioClick} to={`/wooorld/tablet/tours/tour/${_id}`}>
      <Card
        bg={isOwnedByUser
          ? isHovering
            ? '#E5FEE9'
            : '#E5FEE977'
          : isHovering
            ? 'white'
            : 'whiteAlpha.700'
        }
        borderRadius="2xl"
        // h="11em"
        onPointerEnter={(e) => {
          sendAudioHover(e);
          setIsHovering(true);
        }}
        onPointerLeave={() => setIsHovering(false)}
      >
        <CardHeader p={2}>
          <Flex flex="1" gap="1" alignItems="center" justifyContent="space-between" flexWrap="nowrap">
            <Flex alignItems="center">
              <Avatar
                borderColor={ownerActivityStatus === 'online' ? 'lightgreen' : 'null'}
                name={alias}
                showBorder
                size="xs"
                src={profilePhotoURL}
              />
              <Heading marginLeft={1} fontWeight="normal" size="xs" noOfLines={1}>{alias}</Heading>
            </Flex>
            <Flex>
              {panoCount === 5 && (
                <InAppTooltip message={`This tour has a 360 image at each stop`}>
                  <MdCheckCircle color="#64B23E" size="1.5em"/>
                </InAppTooltip>)}
            </Flex>
          </Flex>
        </CardHeader>

        <CardBody
          align="center"
          paddingY={0}
          maxH="5.5em"
        >
          <Text fontSize="md" noOfLines={3}>
            {isHovering
              ? (<>
                <Text as="span" fontWeight={500}>Original prompt: </Text>
                <Text as="span" color="wooorldBlue._">{originalPrompt}</Text></>)
              : promptInterpreted}
          </Text>
          <br/>
          <br/>
          <br/>
        </CardBody>

        <CardFooter paddingY={1}>

          <Flex alignItems="center" justifyContent="space-between" w="full">

            <Flex gap={2} justifyContent="flex-end" w="full">
              <Text noOfLines={1}>{likesCount} {likesCount === 1 ? 'like' : 'likes'}</Text>

              {/* 👍 */}
              {isLikedByUser && (
                <MdThumbUp
                  color="#33bbff"
                  size="1.25em"
                />
              )}

              {/* 👎 */}
              {isDislikedByUser && (
                <MdThumbDown
                  color="red"
                  size="1.25em"
                />
              )}
            </Flex>
          </Flex>

        </CardFooter>
      </Card>
    </Link>
  );
};

export default TourPromptCard;

import {AbsoluteCenter, Box, Card, CardBody, Center, Flex, Heading, Image, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useState} from 'react';
import {BsWikipedia} from 'react-icons/bs';
import {MdPlace, MdVrpano} from 'react-icons/md';
import {TbLocationBroken} from 'react-icons/tb';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {textToSpeechStatusSet} from '../../reducers/uiReducer';
import {sendAudioClick, sendNavTo, sendPanoUnload, sendTextToSpeech} from '../../vuplex';
import InAppMapPin from './InAppMapPin';
import InAppTooltip from './InAppTooltip';

const TourStopCard = ({ // see ai.js tourCreate populatePlace - these fields originate from placeObj
  address,
  description,
  googlePlace,
  isCurrentStop,
  isOwnedByUser,
  isPanoIdSetByAi,
  isPinCoordinatesSetByAi,
  name,
  ownerAlias,
  panoId,
  pinCoordinates,
  placeIndex,
  promptId,
  wikiPageId,
  wikiSearch,
  wikiSummary,
  wikiThumbnail,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isPinHovering, setIsPinHovering] = useState(false);
  const isToolOn = useSelector(s => s.inApp.isToolOn);
  const tourStopNumber = placeIndex + 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {panoId: inAppPanoId} = useSelector(state => state.inApp);

  const handleCardClick = ({clientX, clientY} = {}) => {
    if (!isCurrentStop) navigate(placeIndex.toString());

    dispatch(textToSpeechStatusSet('pending'));
    sendTextToSpeech(`stop number ${tourStopNumber}. ${name}. ${description}`);

    if (panoId !== inAppPanoId) sendPanoUnload();

    if (pinCoordinates) {
      sendNavTo({
        ...pinCoordinates,
        panoId,
      });
    }
  };

  const googleThumbnail = _.get(googlePlace, 'photos.0.photoUrl');
  const thumbnail = _.get(wikiThumbnail, 'source') || googleThumbnail || '';
  const pinThumbnail = thumbnail
    .replace('320px', '100px')
    .replace('maxwidth=800', 'maxwidth=100');


  return (
    <Card
      borderRadius="2xl"
      bg={isCurrentStop ? 'whiteAlpha.400' : null}
      border={isCurrentStop ? 'solid 1px #FF85A2' : null}
      overflow="hidden"
      shadow={isHovering || isPinHovering ? `outline` : null}
    >
      <CardBody p="none">
        <Flex
          onPointerEnter={() => {
            if (isCurrentStop) return;
            setIsHovering(true);
          }}
          onPointerLeave={() => {
            if (isCurrentStop) return;
            setTimeout(() => setIsHovering(false), 100);
          }}
        >
          {!isCurrentStop && (
            <Box
              bg="bg"
              border="solid 2px white"
              borderRadius="2xl"
              flexShrink={0}
              h="9em"
              overflow="hidden"
              position="relative"
              w="9em"
            >
              {thumbnail && (
                <Image
                  filter={isHovering ? 'brightness(40%)' : null}
                  fit="cover"
                  h="full"
                  src={thumbnail}
                  w="full"
                />)}

              {isHovering && (
                <AbsoluteCenter>
                  {pinCoordinates
                    ? (
                      <ButtonMinor onClick={handleCardClick}>
                        Take me there
                      </ButtonMinor>
                    )
                    : (
                      <Box alignContent="center">
                        <Center>
                          <TbLocationBroken size={40}/>
                        </Center>
                        <Text whiteSpace="nowrap">No location found</Text>
                      </Box>
                    )}

                </AbsoluteCenter>
              )}
            </Box>
          )}


          <Box
            borderLeftRadius="md"
            flexGrow={1}
            onClick={isCurrentStop ? null : (e) => {
              sendAudioClick(e);
              handleCardClick();
            }}
            p="1em"
          >
            <Flex justifyContent="space-between">
              <Heading
                as="h2"
                size="sm"
                marginBottom={2}
                noOfLines={1}
              >
                {`Stop ${tourStopNumber} - ${name}`}
              </Heading>

              <Flex p={0} gap={2}>
                <InAppTooltip
                  message={isPinCoordinatesSetByAi
                    ? `Coordinates set by AI`
                    : pinCoordinates
                      ? `Coordinates set by ${ownerAlias}`
                      : `No coordinates found for this stop`}
                >
                  <MdPlace
                    color={isPinCoordinatesSetByAi
                      ? '#FF85A2'
                      : pinCoordinates
                        ? '#33bbff'
                        : '#BFBFBF'}
                    size="1.3em"
                  />
                </InAppTooltip>

                <InAppTooltip
                  message={isPanoIdSetByAi
                    ? `360 set by AI`
                    : panoId
                      ? `360 set by ${ownerAlias}`
                      : `No 360 found for this stop`}
                >
                  <MdVrpano
                    color={isPanoIdSetByAi
                      ? '#FF85A2'
                      : panoId
                        ? '#33bbff'
                        : '#BFBFBF'}
                    size="1.3em"
                  />
                </InAppTooltip>

                <InAppTooltip
                  message={wikiSummary ? 'Wikipedia article available' : 'No Wikipedia article for this stop'}
                >
                  <BsWikipedia
                    color={wikiSummary ? '#33bbff' : '#BFBFBF'}
                    size="1.3em"
                  />
                </InAppTooltip>
              </Flex>
            </Flex>


            <Text
              fontSize="0.9em"
              noOfLines={4}
            >
              {description}
            </Text>
          </Box>


        </Flex>
      </CardBody>

      {pinCoordinates && isToolOn && (
        <InAppMapPin
          color="#FF9933"
          label={`${tourStopNumber}. ${name}`}
          iconUrl={pinThumbnail}
          isHovering={isHovering}
          latitude={pinCoordinates.lat}
          longitude={pinCoordinates.lon}
          onClick={handleCardClick}
          onHoverEnter={() => setIsPinHovering(true)}
          onHoverExit={() => setIsPinHovering(false)}
          tooltip="Tour Pin <br> Only visible to you"
        />)}
    </Card>
  );
};

export default TourStopCard;

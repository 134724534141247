import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  propCreateOrUpdateRequest,
  propDeleteByIdRequest,
  propsListRequest
} from '../../../reducers/gooodReducer';
import TableEditor from '../TableEditor';

const PropsTable = () => {
  const {isLoading, response} = useSelector(s => s.goood.permissionsAdmin.props);
  const items = response || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(propsListRequest());
  }, []);

  return (
    <TableEditor
      fields={[
        {
          isRequired: true,
          key: 'name',
          placeholder: 'propName',
        },
        {
          key: 'comment',
          placeholder: 'A note about this prop.',
        },
      ]}
      isLoading={isLoading}
      items={items}
      onCreateOrUpdate={propCreateOrUpdateRequest}
      onDelete={propDeleteByIdRequest}
      title="Props"
    />);
};

export default PropsTable;

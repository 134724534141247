import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Skeleton
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  folderClose,
  pinsFolderRequest,
  pinsListFolderResultsClear, pinsListFolderSortSet,
  pinsSearchFilterSet,
  pinsSearchResultsClear
} from '../../reducers/pinsReducer';
import PinFolderCard from './PinFolderCard';
import PinsList from './PinsList';
import PinsListController from './PinsListController';
import PinsListFilters from './PinsListFilters';


const PinFolderDrawer = () => {
  const isLoading = useSelector(s => s.pins.pinsListFolder.isLoading);
  const folderOpened = useSelector(s => s.pins.folderOpened);
  const dispatch = useDispatch();

  const [folderOpenedDelayed, setFolderOpenedDelayed] = useState(folderOpened);

  useEffect(() => {
    setTimeout(() => setFolderOpenedDelayed(folderOpened), 333);
  }, [folderOpened]);

  return (
    <Drawer
      isOpen={!!folderOpened}
      placement="right"
      onClose={() => {
        dispatch(folderClose());
      }}
      size="md"
      // finalFocusRef={} // The ref of element to receive focus when the modal closes.
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <DrawerHeader>
          <Center fontWeight="normal" fontSize="14px">
            <PinFolderCard folder={folderOpened} labelSize="md" height="8em" width="80%" />
          </Center>
        </DrawerHeader>

        <DrawerBody
          bg="bg"
          p={0}
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '8px',
              backgroundColor: `rgba(0, 0, 0, 0.07)`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.07)`,
            },
          }}
        >
          <Skeleton isLoaded={!!folderOpenedDelayed}>
            <PinsListFilters
              onSortSet={pinsListFolderSortSet}
              paddingX={1}
              stateKey="pinsListFolder"
              textInputStateKey="txtInputPlacesInList"
              top={0}
            />
            {!!folderOpenedDelayed && ( // delay requesting data
              <PinsListController
                onClearPins={pinsListFolderResultsClear}
                onRequestPins={pinsFolderRequest}
                stateKey="pinsListFolder"
                textInputStateKey="txtInputPlacesInList"
              />
            )}
            <PinsList
              columns={2}
              onRequestPins={pinsFolderRequest}
              stateKey="pinsListFolder"
              textInputStateKey="txtInputPlacesInList"
            />
          </Skeleton>
        </DrawerBody>

        {/*<DrawerFooter>*/}
        {/*</DrawerFooter>*/}
      </DrawerContent>
    </Drawer>
  );


};

export default PinFolderDrawer;

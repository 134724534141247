import {Box, ButtonGroup, Button, Spacer, Flex, MenuItem, Menu, MenuList, MenuButton, Text, Stack} from '@chakra-ui/react';
import BingMapsReact from 'bingmaps-react';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {gooodPost} from "../../gooodApi";
import {ChevronDownIcon} from "@chakra-ui/icons";
import PageTitle from '../WooorldInApp/PageTitle';

const GooodMap = () => {
  const {bingMapsApiKey, demoWorldId} = useSelector(s => s.goood.auth.response);
  const [map, setMap] = useState(undefined);
  const [pins, setPins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeButton, setActiveButton] = useState('');
  const [mapBounds, setMapBounds] = useState(undefined);
  const [currentWITWMapFilter, setCurrentWITWMapFilter] = useState('USA');

  useEffect(() => {
    if (map) {
      if(activeButton === 'publicPins') {
        getPublicPins(map);
      }
      if(activeButton === 'allPins') {
        getAllPins(map);
      }
      if(activeButton === 'witwUSA') {
        getWitwUSAPins(map);
      }
      if(activeButton === 'witwEU') {
        getWitwEUPins(map);
      }
      if(activeButton === 'witwALL') {
        getWitwALLPins(map);
      }
    }
  }, [map, mapBounds, activeButton])

  const getPublicPins = (map)=> {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting public pins');
    gooodPost('/pins/clusters', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]},
        topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      },
      worldId: demoWorldId, //FIXME: Remove need for World ID for Goood API
      zoom: map.getZoom(),
    })
      .then(({data}) => {
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  }

  const getAllPins = (map) => {
    //TODO: Get all pins
    setPins([]); //FIXME: Remove this line when getAllPins is implemented
  }

  const getWitwUSAPins = (map) => {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting USA witw pins');
    gooodPost('/witw-pins', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]},
        topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      },
      zoom: map.getZoom(),
      map: 'USA'
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  }

  const getWitwEUPins = (map) => {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting EU witw pins');
    gooodPost('/witw-pins', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]},
        topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      },
      zoom: map.getZoom(),
      map: 'EU'
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  }

  const getWitwALLPins = (map) => {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting ALL witw pins');
    gooodPost('/witw-pins', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]},
        topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      },
      zoom: map.getZoom(),
      map: 'ALL'
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  }

  const getRandomWITWPins = () => {
    setIsLoading(true);
    console.log('Getting random witw pins');
    gooodPost('/witw-pins/random', {
      map: currentWITWMapFilter
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  }

  const handleClick = (button) => {
    if(button === activeButton) {
      setActiveButton('');
      return;
    }
    if(button === 'witwRandom'){
      setActiveButton('');
      getRandomWITWPins();
      return;
    }
    setActiveButton(button);
    console.log(`Active button is now: ${button}`);
  }

  const handleMenuChange = (option) => {
    setCurrentWITWMapFilter(option);
  }

  const pushPins = pins.map(({_id, label, location: {Latitude, Longitude}}) => ({
    center: {
      latitude: Latitude,
      longitude: Longitude,
    },
    options: {
      title: label
    },
    infobox: {
      closeDelayTime: 100,
      description: `${_id}`,
      title: label,
      zIndex: 1000,
  }
  }));

  // FIXME: map throws an error when props change while it is initializing https://github.com/milespratt/bingmaps-react/issues/56 // Can be ignored if we switch to google maps
  // TODO: swap out bingmaps-react for react-google-maps => https://www.npmjs.com/package/@react-google-maps/api

  return (
    <Flex
      bgGradient="linear(to-b, #DADADA, wooorldLavender.100)"
      borderRadius="lg"
      flexWrap="wrap"
      gap={1}
      padding={2}
      position="fixed"
      w="full"
      h='full'
    >
      <PageTitle title="Map Data - Goood"/>

      <Box w='100%' h='100%'>
        <Box w='100%' h='40px'>
          <Flex align='center'>
            <Stack direction='row' spacing={4}>
              <Stack direction='row' align='center'>
                <Text fontSize='lg' color='blackAlpha.800'>Pins </Text>
                <ButtonGroup size='sm' isAttached>
                  <Button colorScheme='blue' isActive={activeButton === 'publicPins'}
                          onClick={() => handleClick('publicPins')}>
                    Public
                  </Button>
                  <Button colorScheme='blue' isActive={activeButton === 'allPins'}
                          onClick={() => handleClick('allPins')}>
                    All
                  </Button>
                </ButtonGroup>
              </Stack>
              <Stack direction='row' align='center'>
                <Text fontSize='lg' color='blackAlpha.800'>WITW </Text>
                <Spacer />
                <ButtonGroup size='sm' isAttached>
                  <Button colorScheme='pink' isActive={activeButton === 'witwUSA'}
                          onClick={() => handleClick('witwUSA')}>
                    USA
                  </Button>
                  <Button colorScheme='pink' isActive={activeButton === 'witwEU'}
                          onClick={() => handleClick('witwEU')}>
                    EU
                  </Button>
                  <Button colorScheme='pink' isActive={activeButton === 'witwALL'}
                          onClick={() => handleClick('witwALL')}>
                    All
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
            <Spacer />
            <Stack direction='row' spacing={4} align='center'>
              <Text fontSize='lg' color='blackAlpha.800'>Random WITW </Text>
              <ButtonGroup size='sm' isAttached>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    {currentWITWMapFilter}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => handleMenuChange('USA')}>USA</MenuItem>
                    <MenuItem onClick={() => handleMenuChange('EU')}>EU</MenuItem>
                    <MenuItem onClick={() => handleMenuChange('World')}>World</MenuItem>
                  </MenuList>
                </Menu>
                <Button colorScheme='pink' isActive={activeButton === 'witwRandom'}
                        onClick={() => handleClick('witwRandom')}>
                  WITW Random - {currentWITWMapFilter} Point
                </Button>
              </ButtonGroup>
            </Stack>
          </Flex>
        </Box>
        <BingMapsReact // https://github.com/milespratt/bingmaps-react
          bingMapsKey={bingMapsApiKey}
          height="85%"
          width="100%"
          mapOptions={{ // https://learn.microsoft.com/en-us/bingmaps/v8-web-control/map-control-api/mapoptions-object
            navigationBarMode: 'square',
          }}
          onMapReady={({map: {current}}) => {
            console.log('onMapReady', current);
            if (map) return;
            setMap(current);

            current.viewchangeend.add(() => {
              const {bounds} = current.getBounds();
              setMapBounds(bounds);
            });
          }}
          pushPinsWithInfoboxes={pushPins}
          viewOptions={{ // https://learn.microsoft.com/en-us/bingmaps/v8-web-control/map-control-api/viewoptions-object
            // center: {latitude: 42.360081, longitude: -71.058884},
            // mapTypeId: 'grayscale',
          }}
        />
      </Box>
    </Flex>
  );
};

export default GooodMap;

import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useVuplex} from '../../hooks/useVuplex';
import {isNewDataAvailableSet, pinEditFormSet} from '../../reducers/pinsReducer';

const PinsVuplex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const isEditingPin = pathname.endsWith('pins/edit');

  useVuplex({
    pinEditRequest: ({data}) => {
      dispatch(pinEditFormSet({
        ...data,
        label: data._id
          ? data.label
          : data.label || data.suggestedLabel,
      }));

      if (!isEditingPin) {
        navigate('pins/edit');
      }
    },
    pinNewData: () => {
      dispatch(isNewDataAvailableSet(true));
    },
  }, [isEditingPin]);

  return null;
};

export default PinsVuplex;

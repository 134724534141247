import {
  AbsoluteCenter, Box,
  Center,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import ButtonMinor from '../../components/ButtonMinor';
import HasPermission from '../../components/HasPermission';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import {useLobbyHelp} from '../../hooks/useLobbyHelp';
import {
  exploreWorldsPrivate,
  exploreWorldsPublic,
  exploreWorldsSolo,
  playWorldsPrivate,
  playWorldsPublic,
  playWorldsSolo
} from '../../hooks/usePermissions';
import {sendAudioClick, sendWorldCreate} from '../../vuplex';
import UpgradeButton from './UpgradeButton';
import WorldCardBlank from './WorldCardBlank';

const WorldCreationCard = ({
  isWitw,
}) => {
  const {isOpen, onToggle, onClose} = useDisclosure();

  const [privacy, setPrivacy] = useState('public');

  const dummyWorld = {
    name: isWitw ? `New Game World` : `New Exploration World`,
    rules: {isWitw}
  };

  const isHelpStep2 = useLobbyHelp(2);
  const isHelpStepExploreWitw = useLobbyHelp(isWitw ? 6 : 5);
  const isHelpStep7 = useLobbyHelp(7) && !isWitw;
  const isHelpStep8 = useLobbyHelp(8) && !isWitw;
  const isOpenForHelp = isHelpStep7 || isHelpStep8

  useEffect(() => {
    if (isHelpStep8) {
      const privacyOpts = ['solo', 'private', 'public'];
      let i = 0;
      setPrivacy(privacyOpts[i]);

      const intervalId = setInterval(() => {
        i++;
        if (i > 2) {
          clearInterval(intervalId);
          return;
        }
        setPrivacy(privacyOpts[i]);
      }, 1000);
    }
  }, [isHelpStep8]);

  return (
    <>
      <WorldCardBlank
        isHelpHighlighted={isHelpStep2 || isHelpStepExploreWitw}
        landscapeHeight={130}
        onClick={(evt) => {
          sendAudioClick(evt);
          onToggle();
        }}
        world={dummyWorld}
      >
        <Stack
          color="white"
          textShadow={`0px 2px 6px #000000aa`}
          textAlign="center"
          h="full"
          w="full"
        >
          <Box>
            <Text
              fontSize="xl"
              fontWeight={800}
              paddingTop={2}
            >
              {isWitw ? `Game Worlds:` : `Exploration Worlds:`}
            </Text>

            <Text
              fontSize="md"
              paddingX={8}
            >
              {isWitw
                ? `Try to guess your location in Where in the Wooorld`
                : `Explore a 3D world, save places, create tours and more`}
            </Text>
          </Box>


          <Box>
            <ButtonMinor tooltip={isWitw ? `Click to create a new Game World` : `Click to create a new Exploration World`}>
              Create {isWitw ? `Game World` : `Exploration World`}
            </ButtonMinor>
          </Box>

        </Stack>
      </WorldCardBlank>

      <Modal
        isOpen={isOpenForHelp || isOpen}
        onClose={onClose}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px)"
        />

        <ModalContent
          bg="none"
          borderRadius="2xl"
          marginBottom="0.5rem"
          marginTop="8rem"
          // maxHeight="calc(100% - 1.8rem)"
        >
          <WorldCardBlank
            height="14em"
            landscapeHeight={150}
            world={dummyWorld}
          >
            <Flex
              flexFlow="column"
              gap={4}
              p={4}
              textAlign="center"
            >
              <Text color="white" marginTop={10} textShadow={`0px 2px 4px #00000077`}>
                Select a privacy option
              </Text>

              <Center>
                <RadioButtonGroup
                  groupName="privacy-options"
                  options={[
                    {
                      activeBg: 'soloGrey',
                      label: 'Solo',
                      tooltip: 'Only you can join',
                      value: 'solo',
                      ...isOpenForHelp
                        ? {forceShowPremiumStar: true}
                        : {
                          permissionBlockedProps: {
                            borderRight: 'solid 2px #BFBFBF!important',
                          },
                          permission: isWitw ? playWorldsSolo : exploreWorldsSolo,
                          permissionTooltip: isWitw ? 'Player+ only' : 'Explorer+ only',
                        },
                    },
                    {
                      activeBg: 'privateOrange',
                      label: 'Private',
                      tooltip: 'Only invited people can join',
                      value: 'private',
                      ...isOpenForHelp
                        ? {forceShowPremiumStar: true}
                        : {
                          permission: isWitw ? playWorldsPrivate : exploreWorldsPrivate,
                          permissionTooltip: isWitw ? 'Player+ only' : 'Explorer+ only',
                        },
                    },
                    {
                      activeBg: 'publicGreen',
                      label: 'Public',
                      permission: isWitw ? playWorldsPublic : exploreWorldsPublic,
                      permissionTooltip: isWitw ? 'Player+ only' : 'Explorer+ only',
                      tooltip: 'Anyone can join',
                      value: 'public',
                    },
                  ]}
                  onChange={setPrivacy}
                  value={privacy}
                />
                <HasPermission
                  permission={isOpenForHelp ? null : isWitw ? playWorldsSolo : exploreWorldsSolo}
                  fallbackContent={
                    <UpgradeButton
                      descriptor="WorldCreationCard"
                      left="29.5%"
                      top="45%"
                      transform="scale(75%)"
                      packageName={isWitw ? 'player+' : 'explorer+'}
                      position="absolute"
                    />
                  }
                />
              </Center>

              <Flex paddingTop={6} gap={4} justifyContent="center">
                <ButtonMinor
                  h="3em"
                  onClick={() => {
                    if (isOpenForHelp) return;

                    const config = {
                      private: {
                        isGame: isWitw,
                        isPrivate: true,
                      },
                      public: {
                        isGame: isWitw,
                      },
                      solo: {
                        isGame: isWitw,
                        isSolo: true,
                      },
                    }[privacy];

                    sendWorldCreate(config);
                    onClose();
                  }}
                  tooltip={`Create a ${privacy} ${isWitw ? 'game' : 'explorer'} world.`}
                >
                  ✔ Confirm
                </ButtonMinor>

                <ButtonMinor
                  h="3em"
                  onClick={() => {
                    onClose();
                  }}
                >
                  ❌ Cancel
                </ButtonMinor>
              </Flex>

            </Flex>


          </WorldCardBlank>
        </ModalContent>

      </Modal>
    </>
  );


};

export default WorldCreationCard;

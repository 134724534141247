import {SmallCloseIcon} from '@chakra-ui/icons';
import {Button, Flex, StackDivider, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import {pinsFilterOptions} from '../../reducers/pinsReducer';
import {textInputPlaceholderSet} from '../../reducers/uiReducer';
import {sendAudioClick} from '../../vuplex';
import Filters from './Filters';
import InputBar from './InputBar';

const PinsListFilters = ({
  onFilterSet = null,
  onSortSet,
  paddingX = 2,
  stateKey,
  textInputStateKey,
  top,
}) => {
  const dispatch = useDispatch();
  const filter = useSelector(s => s.pins[stateKey].filter);
  const filterOptions = useSelector(s => s.pins[stateKey].filterOptions);
  const sort = useSelector(s => s.pins[stateKey].sort);
  const sortOptions = useSelector(s => s.pins[stateKey].sortOptions);


  const handleFilterClick = (filterClicked) => () => {
    dispatch(onFilterSet(filterClicked));
  };

  const handleSortClick = (sortClicked) => () => {
    dispatch(onSortSet(sortClicked));
  };

  useEffect(() => {
    dispatch(textInputPlaceholderSet({key: textInputStateKey, val: 'Search saved places'}));
  }, []);

  return (
    <InputBar
      paddingX={paddingX}
      stateKey={textInputStateKey}
      top={top}
      contentRight={
        <Flex alignItems="center" justifyContent="flex-end" gap={2} w="33%">
          {filter && (
            <ButtonMinor
              alignItems="center"
              bg="wooorldBlue._"
              fontSize="1.5em"
              onClick={handleFilterClick(null)}
              position="relative"
            >
              {filterOptions.find(p => p.value === filter).emoji}
              <SmallCloseIcon
                background="red"
                borderRadius="full"
                color="white"
                pointerEvents="none"
                position="absolute"
                bottom="-.25em"
                right="-.25em"
                fontSize=".75em"
              />
            </ButtonMinor>
          )}


          <Filters isActive={filter || !_.includes(['likes', 'recommended'], sort)}>
            {onFilterSet && (<>
              <Text color="lightgrey">Filter</Text>
              {filterOptions.map(({value, label}, idx) => {
                const isSelected = filter === value;
                return (
                  <ButtonMinor
                    bg={isSelected ? 'wooorldBlue._' : null}
                    color={isSelected ? 'white' : null}
                    key={idx}
                    justifyContent="left"
                    onClick={handleFilterClick(value)}
                    position="relative"
                    size="sm"
                  >
                    {label}
                  </ButtonMinor>
                );
              })}

              <StackDivider border="solid 1px grey"/>
            </>)}

            <Text color="lightgrey">Sort by</Text>
            {sortOptions.map(({value, label, isSeparator}, idx) => {
              const isSelected = sort === value;
              return (
                <ButtonMinor
                  bg={isSelected ? 'wooorldBlue._' : null}
                  color={isSelected ? 'white!important' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handleSortClick(value)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </ButtonMinor>
              );
            })}
          </Filters>
        </Flex>
      }
    />
  );
};

export default PinsListFilters;

import {Center, Flex, Image, Spinner, Text} from '@chakra-ui/react';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import logo from '../../assets/wooorld-color.png';
import BackToTopButton from '../../components/BackToTopButton';
import {clearTokens, setAccessToken, setBingMapsApiKey} from '../../gooodApi';
import {addScript, removeScript} from '../../helpers/addRemoveScript';
import {
  authSet,
  authSetFromSessionStorage,
  loginGoogleRequest,
  toursAdminListClear,
  usersAdminListClear,
  usersAdminListRequest,
  usersOnlineCountRequest, worldsAdminListClear
} from '../../reducers/gooodReducer';
import {getVuplexMessageHandlers, sendAuthRequest} from '../../vuplex';
import NavButtons from '../WooorldInApp/NavButtons';
import Tours from '../WooorldInApp/Tours';
import GooodMap from './GooodMap';
import PermissionsAdmin from './PermissionsAdmin/PermissionsAdmin';
import ToursAdmin from './ToursAdmin';
import UsersList from './UsersList';
import WorldsList from './WorldsList';


const Goood = () => {
  const [isGoogleInit, setIsGoogleInit] = useState(false);
  const [scriptId, setScriptId] = useState();
  const dispatch = useDispatch();
  const isLoggedIn = !!useSelector(s => s.goood.auth.response);
  const isLoggingIn = useSelector(s => s.goood.auth.isLoading);


  useEffect(() => {
    dispatch(authSetFromSessionStorage());
  }, []);

  const loginButtonRef = useCallback((loginButton) => {
    if (isGoogleInit && loginButton) {
      window.google.accounts.id.renderButton(
        loginButton,
        {theme: 'outline', size: 'large'}  // customization attributes
      );
    }

  }, [isGoogleInit]);

  useEffect(() => {
    if (scriptId) return; // don't add script while we are hot-reloading

    const onLoadGoogle = () => {
      const {google} = window;

      google.accounts.id.initialize({
        client_id: '526495548935-1405j1gifbborqu1mfa8i6t39ntvuvq2.apps.googleusercontent.com', //TODO: get from https://create-react-app.dev/docs/adding-custom-environment-variables

        // https://developers.google.com/identity/gsi/web/guides/display-button#credential_handling
        // TODO: Where you choose to receive the JWT depends upon if you render the button using HTML or JavaScript and if popup or redirect UX mode is used.
        callback: async ({credential}) => {
          dispatch(loginGoogleRequest({credential}));
        }
      });

      setIsGoogleInit(true);
      // google.accounts.id.prompt(); // also display the One Tap dialog
    };

    const script = addScript({
      src: `https://accounts.google.com/gsi/client`,
      id: 'login-with-google-script',
      onLoad: onLoadGoogle,
    });

    setScriptId(script.id);

    return () => removeScript({id: script.id}); // removes the script on component unmount - does not unload google though.
  }, [scriptId]);


  useEffect(() => { // TODO: useVuplex - need to handle sendAuthRequest, which is different from any other use since this is the front door
    const handleVuplexMessage = getVuplexMessageHandlers({
      auth: ({accessToken, mapApiKey, refreshToken}) => {
        clearTokens();
        setAccessToken(accessToken);// always use accessToken from app, refreshing here would logout app
        setBingMapsApiKey(mapApiKey);
        dispatch(authSet({
          accessToken,
          bingMapsApiKey: mapApiKey
        }))
      },
    });

    const addMessageListener = () => {
      window.vuplex.addEventListener('message', handleVuplexMessage);
      sendAuthRequest();
    };

    if (window.vuplex) {
      addMessageListener();
    } else {
      window.addEventListener('vuplexready', addMessageListener);
    }

    // cleanup
    return () => {
      if (window.vuplex) {
        console.log('remove message listener');
        window.vuplex.removeEventListener('message', handleVuplexMessage);
      }
    };
  }, []);



  return (
    <Flex
      bg="#d2e3fc"
      flexDir="column"
      // h="100vh"
    >
      <Flex
        bg="#282c34"
        justifyContent="space-between"
        overflow="hidden"
        p={2}
        flexShrink={0}
        position="sticky"
        top={0}
        zIndex="sticky"
      >
        <Image h={10} src={logo}/>
        {isLoggedIn
          ? <Text fontSize="1.4em">goood mode</Text>
          : isLoggingIn ? <Spinner />
            : <div ref={loginButtonRef}/>}
      </Flex>

      {isLoggedIn && (
        <>
          <Center
            flexShrink={0}
            p={1}
          >
            <NavButtons
              linkConfigs={[
                {
                  label: 'Users',
                  onClick: () => {
                    dispatch(usersAdminListClear());
                    dispatch(usersOnlineCountRequest());
                  },
                  to: 'users',
                  w: '8em',
                },
                {
                  label: 'Worlds',
                  onClick: () => {
                    dispatch(worldsAdminListClear());
                  },
                  to: 'worlds',
                  w: '8em',
                },
                {
                  label: 'Permissions',
                  onClick: () => {
                  },
                  to: 'permissions',
                  w: '8em',
                },
                {
                  label: 'MapData',
                  onClick: () => {
                    // dispatch(usersListRequest());
                  },
                  to: 'mapData',
                  w: '8em',
                },
                {
                  label: 'Tours',
                  onClick: () => {
                    dispatch(toursAdminListClear({}));
                  },
                  to: 'tours',
                  w: '8em',
                },

              ]}
            />
          </Center>

          <Flex flex={1}>
            <Routes>
              <Route path="users/*" element={<UsersList/>}/>
              <Route path="worlds/*" element={<WorldsList/>}/>
              <Route path="mapData/*" element={<GooodMap/>}/>
              <Route path="tours/:promptId" element={<ToursAdmin/>}/>
              <Route path="tours" element={<ToursAdmin/>}/>
              <Route path="permissions/*" element={<PermissionsAdmin/>}/>
              <Route path="*" element={<Navigate to="users" replace={true}/>}/>
            </Routes>
          </Flex>

          <BackToTopButton />
        </>
      )}
    </Flex>
  );
};

export default Goood;





import {Button, Flex, Text, useToast} from '@chakra-ui/react';
import React, {useState} from 'react';
import {BsPersonCheck} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useResolvedPath} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import OptionsMenu from '../../components/OptionsMenu';
import {
  folderDeleteRequest,
  folderEditFormSaveRequest,
  folderEditFormSet,
  folderEditFormUpdate, pinDeleteRequest, pinEditFormSet
} from '../../reducers/pinsReducer';
import {sendAudioClick, sendReportPlace} from '../../vuplex';
import PermissionBadge from './PermissionBadge';

const PinOptionsMenu = ({
  pin,
}) => {
  const {
    _id,
    address,
    isInPrivateFolder,
    isInSharedFolder,
    isOwnedByUser,
    label,
    likes,
    location: {Latitude, Longitude},
    owner,
    ownerActivityStatus,
    ownerAlias,
    ownerProfilePhotoURL,
    streetViewId,
    suggestedLabel,
    zoomLevel,
  } = pin;

  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <>
      {isDeleteMenuOpen && (
        <OptionsMenu isOpenPermanently>
          <Text color="white">Delete "{label}"?</Text>
          <ButtonMinor
            colorScheme="red"
            justifyContent="left"
            onClick={async () => {
              const {error} = await dispatch(pinDeleteRequest({pinId: _id}));

              if (error)
                toast({
                  status: 'error',
                  description: 'Failed to delete pin'
                });
            }}
            position="relative"
            size="sm"
          >
            confirm
          </ButtonMinor>

          <ButtonMinor
            justifyContent="left"
            onClick={() => {
              setIsDeleteMenuOpen(false);
            }}
            position="relative"
            size="sm"
          >
            cancel
          </ButtonMinor>
        </OptionsMenu>
      )}

      {!isDeleteMenuOpen && (
        <OptionsMenu>
          {isOwnedByUser && (
            <ButtonMinor
              justifyContent="left"
              onClick={() => {
                dispatch(pinEditFormSet(pin));
                navigate('edit');
              }}
              position="relative"
              size="sm"
            >
              🖊️ edit
            </ButtonMinor>
          )}

          {isOwnedByUser && (
            <ButtonMinor
              justifyContent="left"
              onClick={() => {
                setIsDeleteMenuOpen(true);
              }}
              position="relative"
              size="sm"
            >
              ❌ delete
            </ButtonMinor>
          )}

          {!isOwnedByUser && (
            <ButtonMinor
              justifyContent="left"
              onClick={() => {
                sendReportPlace({
                  ownerId: owner,
                  placeId: _id,
                })
              }}
              position="relative"
              size="sm"
            >
              😒 report
            </ButtonMinor>
          )}
        </OptionsMenu>
      )}
    </>
  );
};

export default PinOptionsMenu;

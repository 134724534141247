import {ExternalLinkIcon} from '@chakra-ui/icons';
import {Avatar, Badge, Box, Card, CardBody, CardHeader, Flex, Heading, Link, Stack, Tag, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {mixpanelEventsUrl} from '../../helpers/params';
import UserPopover from './UserPopover';
import _ from 'lodash';

const User = ({
  user: {
    _id,
    activityStatus,
    aiMonthlyBudget,
    alias,
    buildNumber,
    dismissedHelpTypes,
    email,
    isAiDisabled,
    // isAmbassador,
    isDev,
    isFounder,
    isVIP,
    profilePhotoURL,
    reportedCount,
    reportedUsers,
    requiresVerification,
    roles = [],
    timeoutCount,
    timeouts,
    worldIsPrivate,
    worldIsSolo,
    worldIsWitw,
    worldName,
  }, user
}) => {

  // FIXME, this is server time
  const latestTimeout = _.last(_.sortBy(timeouts, t => Date.parse(t.expiration)));

  const {isLoading, response: rolesResponse} = useSelector(s => s.goood.permissionsAdmin.roles);
  const rolesAll = rolesResponse || [];

  const hasRole = (roleName) => {
    const roleConfig = rolesAll.find(r => r.name === roleName);
    if (!roleConfig) return false;
    return !!(roles.find(r => r === roleConfig._id));
  }

  const isExplorerPlus = hasRole('explorerPlus');
  const isExplorerPlusFounder = hasRole('explorerPlusFounder');
  const isPlayerPlus = hasRole('playerPlus');
  const isPlayerPlusFounder = hasRole('playerPlusFounder');
  const isAmbassador = hasRole('ambassador');
  const isModerator = hasRole('moderator');
  const isAlpha = hasRole('alphaTester');
  const isBeta = hasRole('betaTester');
  const isTester = hasRole('tester');


  return (
    <Card
      bg="whiteAlpha.700"
      borderRadius="2xl"
    >
      <CardHeader p={2}>
        {/* Top Row nowrap*/}
        <Flex flex="1" gap="1" alignItems="center" justifyContent="space-between" flexWrap="nowrap">

          <Flex alignItems="center" flexWrap="wrap" gap={1}>
            <Avatar
              borderColor={activityStatus === 'online' ? 'lightgreen' : 'grey'}
              name={alias}
              showBorder
              size="xs"
              src={profilePhotoURL}
            />

            {buildNumber && <Tag variant="outline">v{buildNumber}</Tag>}
            {isDev && <Tag variant="outline">👩‍💻</Tag>}
            {isModerator && <Tag variant="outline">🔨</Tag>}
            {isAmbassador && <Tag variant="outline">☑️</Tag>}
            {isExplorerPlus && <Tag variant="outline">🔵</Tag>}
            {isPlayerPlus && <Tag variant="outline">🔴</Tag>}
            {isExplorerPlusFounder && <Tag variant="outline">🔴F</Tag>}
            {isPlayerPlusFounder && <Tag variant="outline">🔵F</Tag>}
            {isFounder && <Tag variant="outline">F</Tag>}
            {isAlpha && <Tag variant="outline">🅰</Tag>}
            {isBeta && <Tag variant="outline">🅱</Tag>}
            {isTester && <Tag variant="outline">🧪</Tag>}
            {!!reportedCount && <Tag variant="outline">{`🚨x${reportedCount}`}</Tag>}
            {!!timeoutCount && <Tag variant="outline">
              {`🪑x${timeoutCount}`}
              {latestTimeout && ` ${latestTimeout.expiration}`}
            </Tag>}
            {isVIP && <Tag variant="outline">🕴VIP</Tag>}
          </Flex>


          <UserPopover user={user}/>
        </Flex>

      </CardHeader>

      <CardBody>
        <Stack gap={1}>
          <Heading size="sm">{alias}</Heading>
          <Heading size="xs">{email}</Heading>
          <Link href={mixpanelEventsUrl(_id)} isExternal>
            {_id} <ExternalLinkIcon mx="2px"/>
          </Link>

          {!!worldName && (
            <Badge
              bg={
                worldIsWitw
                  ? 'playerPink'
                  : 'explorerBlue'
              }
              borderRadius="2xl"
              paddingX={2}
              paddingY={1}
              textTransform="none"
            >
              <Heading size="xs">{worldName}</Heading>
              <Text>
                {worldIsWitw ? '🕹' : '🧭'} &nbsp;
                {`${worldIsSolo ? 'Solo' : worldIsPrivate ? 'Private' : 'Public'} ${worldIsWitw ? 'Game' : 'Exploration'} World`}
              </Text>
            </Badge>
          )}


        </Stack>
      </CardBody>
    </Card>);
};

export default User;

import {Text} from '@chakra-ui/react';
import React from 'react';
import GenericCard from './GenericCard';

const WikipediaCard = ({
  wikiPage,
}) => {
  const {
    coordinates: [{lat, lon}],
    description,
    pageid: wikiPageId,
    thumbnail: {source: photoUrl} = {source: ''},
    title,
  } = wikiPage;

  return (
    <GenericCard
      backgroundColor="whiteAlpha.600"
      description={<Text fontSize="sm">{description}</Text>}
      detailsPath={`./${wikiPageId}`}
      h="14em"
      iconUrl={'https://upload.wikimedia.org/wikipedia/en/thumb/8/80/Wikipedia-logo-v2.svg/100px-Wikipedia-logo-v2.svg.png'}
      isPhotoBackground
      latitude={lat}
      longitude={lon}
      name={title}
      photoUrl={photoUrl}
      pinTooltip="Wikipedia Pin <br> Only visible to you"
      placeType={'wiki'}
    />
  );
}

export default WikipediaCard;

import {CloseIcon, ExternalLinkIcon} from '@chakra-ui/icons';
import {Flex, IconButton, Input} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TbBrowserPlus} from 'react-icons/tb';
import {useEnterKey} from '../../hooks/useEnterKey';
import {sendDragToScroll, sendDragWithinPage} from '../../vuplex';
import InAppWebBrowser from './InAppWebBrowser';

const WebBrowserLauncher = ({onClose}) => {
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEnterKey(() => {
    setUrl(text);
    if (!isOpen) setIsOpen(true);
  });

  return (
    <Flex gap={1}>
      <Input
        bg="white"
        maxW="lg"
        onChange={({currentTarget}) => setText(currentTarget.value)}
        onPointerEnter={sendDragWithinPage}
        onPointerLeave={sendDragToScroll}
        value={text}
      />

      {isOpen && (
        <IconButton
          colorScheme={'wooorldBlue'}
          onClick={() => {
            setUrl(text);
          }}
          icon={<ExternalLinkIcon size="2em"/>}
          isRound
        />)}

      {!isOpen
        ? (
          <IconButton
            colorScheme={'wooorldBlue'}
            icon={<TbBrowserPlus size="2em"/>}
            isRound
            onClick={() => {
              setUrl(text);
              setIsOpen(true);
            }}
          />
        )
        : (
          <IconButton
            colorScheme={'red'}
            icon={<CloseIcon size="2em"/>}
            isRound
            onClick={() => {
              onClose();
            }}/>
        )}

      {isOpen && (
        <InAppWebBrowser
          url={url}
        />
      )}
    </Flex>
  );
};

export default WebBrowserLauncher;

import {
  IconButton, Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import {BsThreeDotsVertical} from 'react-icons/bs';
import UserMessage from './UserMessage';
import UserTimeout from './UserTimeout';


const UserPopover = ({user}) => {
  const {isOpen, onToggle, onClose} = useDisclosure();
  return (
    <Popover
      computePositionOnMount //Note 🚨: We don't recommend using this in a popover/menu intensive UI or page as it might affect scrolling performance.
      isOpen={isOpen}
      onClose={onClose}
      strategy="fixed"
    >
      <PopoverTrigger>
        <IconButton
          colorScheme="wooorldBlue"
          icon={<BsThreeDotsVertical/>}
          isRound
          onClick={onToggle}
          variant="ghost"
        />
      </PopoverTrigger>

      <PopoverContent bg="#666" w="full">
        <PopoverArrow bg="#666"/>
        <PopoverBody>
          <Stack>
            {user.activityStatus === 'online' && <UserMessage onSent={onClose} user={user} />}
            <UserTimeout onSent={onClose} user={user} />
          </Stack>
        </PopoverBody>
      </PopoverContent>

    </Popover>);
};

export default UserPopover;

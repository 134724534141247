import {Button, Image} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import SparkGold from '../assets/freemium/Spark Gold.png';
import {disabledPremiumStripesBgDark} from '../helpers/styles';
import {useInAppTooltip} from '../hooks/useInAppTooltip';
import {isPermissionPremium, usePermissions} from '../hooks/usePermissions';
import {sendAudioClick, sendAudioHover, sendUpgrade} from '../vuplex';
import HasPermission from './HasPermission';


const ButtonMinor = ({
  children,
  disabledReason,
  forceShowPremiumStar,
  isDisabled,
  isRound = true,
  onClick = _.noop,
  permission,
  permissionBlockedProps,
  permissionTooltip,
  tooltip,
  upgradeClickMetadata = { descriptor: 'Generic disallowed button - disregard IapType', packageName: null },
  ...buttonProps
}) => {
  const borderRadius = isRound ? '3xl' : 'lg';
  const isPremium = isPermissionPremium(permission);
  const hasPermission = usePermissions(permission);

  const sharedProps = {
    borderRadius,
    ...buttonProps,
  };

  const content = (
    <>
      {children}
      {(forceShowPremiumStar || (isPremium && hasPermission))&& (
        <Image
          h={6}
          left="0.5em"
          marginLeft="-1em"
          position="relative"
          src={SparkGold}
          top="-0.5em"
        />)}
    </>);

  const tooltipProps = useInAppTooltip(disabledReason || tooltip);
  const tooltipNoPermsProps = useInAppTooltip(tooltip);

  return (
    <HasPermission
      fallbackContent={
        <Button
          {...sharedProps}
          {...permissionBlockedProps}
          {...tooltipNoPermsProps}
          bg={disabledPremiumStripesBgDark}
          isDisabled
          onClick={() => {
            sendUpgrade(upgradeClickMetadata);
          }}
          opacity="1!important"
          variant="buttonMinor"
        >
          {content}
        </Button>
      }
      permission={permission}
    >
      <Button
        {...sharedProps}
        {...tooltipProps}
        isDisabled={isDisabled || !!disabledReason}
        onClick={(evt) => {
          onClick();
          sendAudioClick(evt);
        }}
        onPointerEnter={(e) => {
          sendAudioHover(e);
        }}
        variant="buttonMinor"
      >
        {content}
      </Button>
    </HasPermission>
  );
};

export default ButtonMinor;

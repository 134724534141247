import {Code, Flex, Skeleton} from '@chakra-ui/react';
import React from 'react';
import PageTitle from '../../WooorldInApp/PageTitle';
import BadgesTable from './BadgesTable';
import CreditsTable from './CreditsTable';
import PermissionsTable from './PermissionsTable';
import PropsTable from './PropsTable';
import RolesTable from './RolesTable';
import UserRolesTable from './UserRolesTable';

const PermissionsAdmin = () => {
  return (
    <Skeleton isLoaded={true} w="full">
      <PageTitle title="Permissions - Goood"/>
      <br/>
      <UserRolesTable />
      <br/>
      <RolesTable />
      <br/>
      <PermissionsTable />
      <br/>
      <BadgesTable />
      <br/>
      <PropsTable />
      <br/>
      <CreditsTable />

      <Flex
        bgGradient="linear(to-b, #DADADA, wooorldLavender.100)"
        borderRadius="lg"
        flexWrap="wrap"
        gap={1}
        padding={2}
        position="relative"
        w="full"
      >
        <Code whiteSpace="pre">
          {/*{JSON.stringify(user, null, 2)}*/}
        </Code>
        {/*{testUsers.map((u) =>  <User key={u._id} user={u} />)}*/}
      </Flex>
    </Skeleton>
  )

  // return (
  //   <Item
  //     className="usersList"
  //     renderTitle={() => <>List users &nbsp; <button disabled={isLoading} onClick={refresh}>refresh</button></>}
  //     renderBody={() => <>
  //       <Broadcast gooodPost={gooodPost} userCount={users.filter(({activityStatus}) => activityStatus === 'online').length}/>
  //       {users.map((u) =>  <User gooodPost={gooodPost} key={u._id} user={u} />)}
  //     </> }
  //   />)
};

export default PermissionsAdmin;

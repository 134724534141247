import {ArrowUpIcon} from '@chakra-ui/icons';
import {Slide} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {FiRefreshCw} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {foldersSearchResultsClear, isNewDataAvailableSet, pinsSearchResultsClear} from '../reducers/pinsReducer';
import {googlePlacesNearbyRequest, isReadyToRefreshSet} from '../reducers/placesReducer';
import ButtonMinor from './ButtonMinor';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > window.innerHeight * 1.3) {
        if (!isVisible) setIsVisible(true);
      } else {
        if (isVisible) setIsVisible(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isVisible]);

  const isGoogleRefreshButton = useSelector((s) => s.places.isReadyToRefresh);
  const isNewPlacesDataAvailable = useSelector(s => s.pins.isNewDataAvailable);

  const dispatch = useDispatch();

  return (
    <Slide
      direction="bottom"
      style={{
        paddingBottom: '1em',
        pointerEvents: 'none',
        textAlign: 'center',
        zIndex: 10,
      }}
      in={isGoogleRefreshButton || isNewPlacesDataAvailable || isVisible}
    >
      <ButtonMinor
        bg="black24"
        color="white"
        shadow={isVisible ? 'dark-lg' : null}
        leftIcon={(isGoogleRefreshButton || isNewPlacesDataAvailable) ? <FiRefreshCw/> : <ArrowUpIcon/>}
        onClick={() => {
          if (isGoogleRefreshButton) {
            dispatch(googlePlacesNearbyRequest());
            dispatch(isReadyToRefreshSet(false));
          }

          // TODO: this is a bit heavy handed. should be per-route
          if (isNewPlacesDataAvailable) {
            dispatch(pinsSearchResultsClear());
            dispatch(foldersSearchResultsClear());
            dispatch(isNewDataAvailableSet(false));
          }

          window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          });
        }}
        pointerEvents="auto"
      >
        {isGoogleRefreshButton || isNewPlacesDataAvailable
          ? 'Refresh list'
          : 'Back to top'}
      </ButtonMinor>
    </Slide>
  );
};

export default BackToTopButton;

import {useEffect} from 'react';
import {useComponentId} from '../../hooks/useComponentId';
import {sendWebBrowser, sendWebBrowserUnmount} from '../../vuplex';

const InAppWebBrowser = ({
  color,
  latitude,
  longitude,
  url,
}) => {
  const componentId = useComponentId();

  useEffect(() => {
    sendWebBrowser({
      color,
      componentId,
      latitude,
      longitude,
      url,
    });
  }, [componentId, url]);

  useEffect(() => {
    return () => sendWebBrowserUnmount(componentId);
  }, [componentId]);

  return null;
};

export default InAppWebBrowser;

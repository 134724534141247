import Color from 'color';
import _ from 'lodash';

// https://kwa.ng/procedurally-generated-svg-landscapes/
const Landscape = ({
  height = 200,
  width = 300,
  viewBoxHeight = 40,
  viewBoxWidth = 200,
  world,
}) => {
  const {
    _id,
    isLobby,
    rules,
    name,
    userCount,
  } = world;

  const {
    isPrivate,
    isSolo,
    isWitw
  } = rules || {};

  const seeds = _('abcd' + (name || 'lobby') // 'abcd' to keep it from being too simple and spiky
    .toLowerCase()
    .replace(' ', '')
    .split(''))
    .groupBy(_.identity)
    .mapValues(arr => arr.length)
    .value();
  const keys = _.keys(seeds);
  const layerCount = keys.length;

  const offsetY = viewBoxHeight * .7;
  const layerHeight = viewBoxHeight;
  const baseColor = Color(
    isWitw
      ? '#E83272'
      : isLobby
        ? 'lightgrey'
        : '#2DAFF0'
  );
  let layer = 0;

  return (
    <svg height={height} width={width} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} xmlns="http://www.w3.org/2000/svg">
      {/*<polygon fill="red" strokeWidth={0}*/}
      {/*  points={`0,10 ${viewBoxWidth},10 10,0`} />*/}

      {_.map(seeds, (v, k) => {
        layer++;

        const layerAnchorY = layer * (layerHeight / layerCount) + offsetY;
        let coord = `M 0 ${layerAnchorY} `;

        // back layers have higher peaks for more mountainous effect

        const strToInt = (str, mod) => (1 + k.charCodeAt(0) % mod);

        const a = strToInt(k, 26) / 15
        const b = v / 5;
        const c = strToInt(k, a);
        const d = strToInt(keys[v % layerCount], a);

        // console.log(k, v, a, b, c)


        const amplitude = (a * (layerHeight / layerCount)) + (layerHeight / layer / layerCount);
        const freq1 = (c * 1 / viewBoxWidth * layer / layerCount) + .005;
        const freq2 = a * d * 7 / viewBoxWidth + .005;
        const phase1 = b * 500;
        const phase2 = phase1 + (a * 250);

        for (let i = 0; i < viewBoxWidth + 1; i++) {
          coord += `H ${i} V ${
            (Math.sin(freq1 * (i + phase1)) * amplitude) + // base
            (Math.cos(Math.tan(freq1 * (i * c - layer * 10)) * amplitude / 6 + freq2 * (i + phase2)) * amplitude / 2) + // interference
            (layerAnchorY - layerHeight)
          } `;
        }

        coord += `V ${layerAnchorY} L 0 ${layerAnchorY}`;
        return (<path key={k} d={coord} fill={baseColor.saturate((layer / layerCount) / 2.5).darken((layer / layerCount)).string()}/>);
      })}
    </svg>
  );
};

export default Landscape;

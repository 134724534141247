import {AbsoluteCenter, Box, Heading} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {FiRefreshCw} from 'react-icons/fi';
import {useDispatch} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import {setAccessToken as setApiAccessToken} from '../../api';
import ButtonMinor from '../../components/ButtonMinor';
import ErrorBoundary from '../../components/ErrorBoundary';
import {isToolOnSet, mapLocationSet, panoIdSet, permissionsSet, userIdSet, worldSet} from '../../reducers/inAppReducer';
import {textToSpeechStatusSet} from '../../reducers/uiReducer';
import {getVuplexMessageHandlers, sendAuthRequest, sendDragToScroll, sendTextToSpeechStop} from '../../vuplex';
import BigScreen from './BigScreen';
import Tablet from './Tablet';

const WooorldInApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const dispatch = useDispatch();
  const handleLocationChange = _.debounce(({latitude, longitude, radius, zoom}) =>
    dispatch(mapLocationSet({latitude, longitude, radius, zoom})), 500);

  useEffect(sendDragToScroll, []);

  useEffect(() => { // TODO: useVuplex - need to handle sendAuthRequest, which is different from any other use since this is the front door
    const handleVuplexMessage = getVuplexMessageHandlers({
      auth: ({accessToken, userId}) => {
        setApiAccessToken(accessToken);
        dispatch(userIdSet(userId));
        // TODO: mixpanel.identify(userId) https://docs.mixpanel.com/docs/tracking/reference/javascript-full-api-reference#mixpanelidentify
        setIsLoggedIn(true);
      },
      locationChange: handleLocationChange,
      panoChange: ({panoId}) => dispatch(panoIdSet(panoId)),
      permissions: (({permissions}) => {
        return dispatch(permissionsSet(_(permissions).map(p => [p, p]).fromPairs().value()));
      }),
      worldChange: (world) => dispatch(worldSet(world)),
      textToSpeechFinished: () => {
        dispatch(textToSpeechStatusSet('idle'));
      },
      textToSpeechStarted: () => {
        dispatch(textToSpeechStatusSet('speaking'));
      },
      toolToggled: ({isOn}) => {
        dispatch(isToolOnSet(isOn));
        if (isOn) {
          sendAuthRequest();
        } else {
          sendTextToSpeechStop();
          dispatch(textToSpeechStatusSet('idle'));
        }
      }
    });

    const addMessageListener = () => {
      window.vuplex.addEventListener('message', handleVuplexMessage);
      sendAuthRequest();
    };

    if (window.vuplex) {
      addMessageListener();
    } else {
      window.addEventListener('vuplexready', addMessageListener);
    }

    // cleanup
    return () => {
      if (window.vuplex) {
        console.log('remove message listener');
        window.vuplex.removeEventListener('message', handleVuplexMessage);
      }
    };
  }, []);

  return isLoggedIn && (
    <ErrorBoundary
      fallback={
        <AbsoluteCenter>
          <Box textAlign="center">
            <Heading m={4} w="20em">Something went wrong.</Heading>
            <ButtonMinor
              leftIcon={<FiRefreshCw/>}
              onClick={() => window.location.reload()}
            >
              Please refresh
            </ButtonMinor>
          </Box>
        </AbsoluteCenter>}
    >
      <Routes>
        <Route path="/bigScreen/*" element={<BigScreen/>}/>
        <Route path="/tablet/*" element={<Tablet/>}/>
      </Routes>
    </ErrorBoundary>
  );
};

export default WooorldInApp;





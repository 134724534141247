import {Box} from '@chakra-ui/react';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import BackToTopButton from '../../components/BackToTopButton';
import DevConsole from './DevConsole';
import Explore from './Explore';
import ModalView from './ModalView';
import Pins from './Pins';
import ToolPanelMask from './ToolPanelMask';
import Tours from './Tours';
import TripPlanner from './TripPlanner/TripPlanner';
import Worlds from './Worlds';

const Tablet = () => (
  <>
    <Box p={0}>
      <Routes>
        <Route path="devConsole/*" element={<DevConsole/>}/>
        <Route path="explore/*" element={<Explore/>}/>
        <Route path="pins/*" element={<Pins/>}/>
        <Route path="tours/*" element={<Tours/>}/>
        <Route path="tripPlanner/*" element={<TripPlanner/>}/>
        <Route path="worlds/*" element={<Worlds/>}/>
      </Routes>

      <BackToTopButton/>
      <ModalView/>
    </Box>

    <ToolPanelMask borderHeight={3} borderWidth={2.2}/>
  </>

);

export default Tablet;

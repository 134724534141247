import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {tourGetRequest, tourIsSpokenSet} from '../../reducers/toursReducer';
import {textToSpeechStatusSet} from '../../reducers/uiReducer';
import {sendAudioClick, sendNavTo, sendPanoUnload, sendTextToSpeech, sendTextToSpeechStop} from '../../vuplex';
import Tags from './Tags';
import TourStopCard from './TourStopCard';

const Tour = () => {
  const {aiCompletionId = undefined, promptId} = useParams();
  const {
    isLoading: promptIsLoading,
    isSpoken,
    response: currentPrompt,
  } = useSelector(state => state.tours.prompt);

  const {
    completion: {
      json: {
        intro,
        places = [],
        prompt,
        summary,
        tags = [],
        wikiTitle,
      } = {},
      rawText: completionText,
      tokensCompletion,
      tokensPrompt,
      tokensTotal,
    },
    tourPrompt: {
      _id: tourPromptId,
      alias,
      createdOn,
      gptPrompt,
      isOwnedByUser,
      ownerId,
      prompt: promptOriginal,
      promptInterpreted,
    },
  } = currentPrompt || {completion: {}, tourPrompt: {}};

  const dispatch = useDispatch();
  useEffect(() => {
    // console.log('p', tourPromptId, promptId, promptIsLoading);
    if (tourPromptId !== promptId) {
      dispatch(tourGetRequest({promptId}));
    }
  }, []);

  const speak = (text = promptInterpreted) => {
    dispatch(textToSpeechStatusSet('pending'));
    sendTextToSpeech(text);
  };

  useEffect(() => {
    if (tourPromptId !== promptId) return;
    if (!isSpoken) {
      speak();
      dispatch(tourIsSpokenSet());
    } else {
      dispatch(textToSpeechStatusSet('idle'));
      sendTextToSpeechStop(); // for going back from a tourStop place details
    }
  }, [tourPromptId, promptId]);
  const {panoId: inAppPanoId} = useSelector(state => state.inApp);
  const navigate = useNavigate();

  const flexDir = useBreakpointValue( // https://chakra-ui.com/docs/hooks/use-breakpoint-value
    {
      base: 'column',
      md: 'row',
    },
  )

  return (
    <Skeleton isLoaded={!promptIsLoading}>
      {currentPrompt && (
        <Flex p={2} gap={4} direction={flexDir}>
          <Flex direction="column" w={flexDir === 'row' ? '45%' : '100%'}>
            <Card
              bg="whiteAlpha.400"
              borderRadius="xl"
              border="solid 2px #FF85A2AA"
              h="fit-content"
            >
              <CardHeader>
                <Heading align="center" p={0} size="xs"> {promptInterpreted || promptOriginal}</Heading>
              </CardHeader>

              <CardBody>
                <Text
                  align="center"
                  fontSize="xs"
                  onClick={(evt) => {
                    sendAudioClick(evt);
                    speak(summary);
                  }}
                >
                  {summary}
                </Text>
              </CardBody>
            </Card>

            <Flex justifyContent="center" p={8}>
              <ButtonMinor
                boxShadow={`0px 0px 20px 0px #33bbff`}
                p="2em"
                onClick={() => {
                  navigate('0');

                  dispatch(textToSpeechStatusSet('pending'));

                  const {
                    description,
                    name,
                    panoId,
                    pinCoordinates,
                    tourStopNumber,
                  } = places[0] || {};

                  sendTextToSpeech(`stop number ${1}. ${name}. ${description}`);

                  if (panoId !== inAppPanoId) sendPanoUnload();

                  if (pinCoordinates) {
                    sendNavTo({
                      ...pinCoordinates,
                      panoId,
                    });
                  }

                }}
              >
                Start Tour
              </ButtonMinor>
            </Flex>

          </Flex>


          <Stack spacing={3}>
            <Heading
              textAlign="center"
              bg="white"

              borderRadius="2xl"
              p={2}
              size="sm"
            >
              Tour Stops
            </Heading>


            {places.map((place, idx) => (
              <TourStopCard
                {...place}
                isOwnedByUser={isOwnedByUser}
                ownerAlias={alias}
                placeIndex={idx}
                promptId={tourPromptId}
                key={idx}
              />))}
          </Stack>
        </Flex>)}

      <Center>
        <Text color="grey" size="sm" p="2em">
          Tours is an experimental feature and may produce inaccurate information.
        </Text>
      </Center>

      <Center>
        <Tags tags={tags}/>
      </Center>
    </Skeleton>);
};

export default Tour;

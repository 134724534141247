import {
  Button,
  Flex, IconButton,
  Input, NumberDecrementStepper, NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tag,
  Td,
  Tr
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {BiInfinite} from 'react-icons/bi';
import Multiselect from '../../components/Multiselect';
import {useEnterKey} from '../../hooks/useEnterKey';

const defaultVal = inputType => ({
  budgetsEditor: [],
  multiselect: [],
}[inputType]) || '';

const TableRowEdit = ({onCancel, onSubmit, fields = [], item = {}}) => {
  const initialForm = _.fromPairs(
    fields
      .filter(f => !f.isReadOnly)
      .map(f => [
        f.key,
        _.isUndefined(item[f.key]) ? defaultVal(f.inputType) : item[f.key],
      ]));
  const [form, setForm] = useState(initialForm);

  const {_id} = item;
  const isNew = !_id;


  const isFormInvalid = fields.reduce((acc, {isRequired, key}) => acc || (isRequired && !form[key]), false);

  const handleSubmit = () => {
    if (isFormInvalid) return;
    onSubmit(isNew ? form : {...form, _id});
    setForm(initialForm);
  };

  useEnterKey(handleSubmit);

  return (
    <Tr>
      {fields.map(({
        inputType = 'text',
        isReadOnly = false,
        key,
        options,
        placeholder,
        valToLabel = v => v,
      }) => {
        const label = isReadOnly && valToLabel(item[key]);

        return (
          <Td key={key}>
            {isReadOnly && (
              <Flex gap={1}>
                {Array.isArray(label)
                  ? label.map(l => <Tag key={l}>{l}</Tag>)
                  : label}
              </Flex>
            )}

            {!isReadOnly && inputType === 'multiselect' && (
              <Multiselect
                colorScheme="wooorldBlue"
                onChange={(values) => setForm(({...form, [key]: values}))}
                options={options}
                title={key}
                values={form[key] || ''}
              />
            )}

            {!isReadOnly && inputType === 'number' && (
              <NumberInput
                colorScheme="wooorldBlue"
                defaultValue={0}
                onChange={(val) => setForm(({...form, [key]: val}))}
                options={options}
                step={1}
                type="number"
                value={form[key]}
              >
                <NumberInputField/>
                <NumberInputStepper>
                  <IconButton
                    onClick={() => {
                      setForm(({...form, [key]: Number.MAX_SAFE_INTEGER}));
                    }}
                    borderRadius={0}
                    borderRight="none"
                    borderTop="none"
                    fontSize="unset"
                    icon={<BiInfinite size="1em" />}
                    minW={0}
                    variant="outline"
                  />
                  <NumberIncrementStepper/>
                  <NumberDecrementStepper/>
                </NumberInputStepper>
              </NumberInput>
            )}

            {!isReadOnly && inputType === 'select' && (
              <Select
                colorScheme="wooorldBlue"
                onChange={({ target: { value }}) => setForm(({...form, [key]: value}))}
                placeholder={placeholder || 'pick one'}
                title={key}
                value={form[key]}
              >
                {options.map(({label, value}) => (
                  <option value={value}>{label}</option>
                ))}
              </Select>
            )}

            {!isReadOnly && inputType === 'dateTime' && (
              <Input
                p={1}
                colorScheme="wooorldBlue"
                placeholder={placeholder}
                onChange={({target: {value}}) => setForm(({...form, [key]: value}))}
                size="md"
                type="datetime-local"
                value={form[key] || ''}
              />
            )}

            {!isReadOnly && inputType === 'text' && (
              <Input
                p={1}
                colorScheme="wooorldBlue"
                minW="10em"
                placeholder={placeholder}
                onChange={({target: {value}}) => setForm(({...form, [key]: value}))}
                size="md"
                value={form[key] || ''}
              />
            )}

            {!isReadOnly && _.includes(['budgetsEditor', 'skusEditor'], inputType) && (
              <Td>
                <Flex gap={1}>
                  Editor TODO. Use MongoDB Compass for now
                  {(label || []).map(l => <Tag key={l}>{l}</Tag>)}
                </Flex>
              </Td>
            )}
          </Td>
        );
      })}
      <Td>
        <Button
          colorScheme="wooorldBlue"
          isDisabled={isFormInvalid}
          onClick={handleSubmit}
          size="xs"
        >
          {isNew ? '➕' : '✔'}
        </Button>
        {!isNew && (
          <Button
            onClick={onCancel}
            size="xs"
          >
            ✖
          </Button>
        )}
      </Td>
    </Tr>
  );
};

export default TableRowEdit;
